import AddIcon from "@mui/icons-material/Add";
//material ul imports
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Modal, TableHead, TableSortLabel, Typography } from "@mui/material";
import { Autocomplete, createTheme, ThemeProvider, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { ptBR } from "@mui/material/locale";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

//components
import HelpButtonInput from "../../../components/HelpButtonInput";
import InputData from "../../../components/InputData";
import InputMask from "../../../components/InputMask";
import RegisterArea from "../../../components/RegistrationArea";
import TopMenu from "../../../components/TopMenu";
import { ApplicationEquipmentService } from "../../../service/application_equipment_service";
import { ApplicationModeService } from "../../../service/application_mode_service";
import { CropSeasonService } from "../../../service/crop_season_service";
import { ManagementService } from "../../../service/management_service";
import { PropertyInputService } from "../../../service/property_input_service";
import { PropertyServiceService } from "../../../service/property_service_service";
import { UserService } from "../../../service/user_service";
import { captalizeFirstLetterEacheWord, getDateWithoutTimezone } from "../../../utils/utils";
import styles from "./ManagementCad.module.css";
import Loading from "../../../components/Loading";

const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	width: "100%",
	m: 0,
	p: 0,
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: 100,
	transform: "translate(-50%, -50%)",
};
const styleAdd = {
	color: "#1A877F",
	margin: "0 0 0 5px",
};

const columnForActive = [
	{
		id: "nome",
		label: "Nome"
	},
	{
		id: "data_plantio",
		label: "Data Plantio",
	}
];

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,columns } =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</TableCell>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function ManagementCad() {
	const [searchParams] = useSearchParams();
	const [itemsSafra, setItemsSafra] = useState([]);
	const [itemsProduto, setItemsProduto] = useState([]);

	const [openSafra, setOpenSafra] = useState(false);
	const [openProduto, setOpenProduto] = useState(false);
	const [selectedProduto, setSelectedProduto] = useState(null);
	const [selectedModoAplicacao, setSelectedModoAplicacao] = useState("-1");
	const [inputValueAplicacao, setinputValueAplicacao] = useState("-1");
	const [horas, setHoras] = useState("");
	const [minutos, setMinutos] = useState("");
	const [observacao, setObservacao] = useState("");
	const [operatorsQuantity, setOperatorsQuantity] = useState(1);

	const [inputQuantidade, setInputQuantidade] = useState("");
	const [quantidadeTotal, setQuantidadeTotal] = useState("");
	const [addVolumeCalda, setAddVolumeCalda] = useState(false);
	const [allServices, setAllServices] = useState([]);
	const [allSafras, setAllSafras] = useState([]);
	const [allEquipamentoss, setAllEquipamentos] = useState([]);
	const [allInsumos, setAllInsumos] = useState([]);
	const [allModosAplicacao, setAllModosAplicacao] = useState([]);
	const [selectedService, setSelectedService] = useState(null);
	const [id, setId] = useState(null);
	const [selectOperador, setSelectOperador] = useState(null);
	const [allOperators, setAllOperators] = useState([]);
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const helpIdFinter = searchParams.get("id");
	const [errorMessageAplicação, setErrorMessageAplicação] = useState(false);
	const [errorMessageDataAplicacao, setErrorMessageDataAplicacao] =
		useState(false);
	const [errorMessageSafra, setErrorMessageSafra] = useState(false);
	const [errorMessageEquipamentoApli, setErrorMessageEquipamentoApli] =
		useState(false);
	const [errorMessageQuantidadeTotal, setErrorMessageQuantidadeTotal] =
		useState(false);
	const [errorMessageService, setErrorMessageService] =
			useState(false);
	const [errorMessageOperator, setErrorMessageOperator] =
			useState(false);
	const [errorMessageModoAplicacao, setErrorMessageModoAplicacao] =
		useState(false);
	const [value, setvalue] = useState("");
	const [selected, setSelected] = React.useState([]);
	const keysForActive =columnForActive; //array de chaves
	// const columnsForActive = Object.values(columnForActive); //array de valores
	const [page, setPage] = React.useState(0); //paginação
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //paginação
	const [selectedDate, setSelectedDate] = useState(null);
	let [finalized, setFinalized] = useState(false);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("nome");
	const [firstVersionInput, setFirstVersionInput] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const managementService = new ManagementService();
	const propertyServiceService = new PropertyServiceService();
	const cropSeasonService = new CropSeasonService();
	const propertyInputsService = new PropertyInputService();
	const applicationEquipmentService = new ApplicationEquipmentService();
	const applicationModeService = new ApplicationModeService();
	const userService = new UserService();

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};
	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = dataAllSafras.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	useEffect(() => {
		initData();
	}, []);
	
	function convertToHoursText(number) {
		const hours = Math.floor(number / 60);

		let formattedHours = "";
		if(hours < 10)
			formattedHours = `00${hours}`;
		else if (hours < 100)
			formattedHours = `0${hours}`;
		else
			formattedHours = hours.toString();
		
		return formattedHours;
	}

	function convertToMinutesText(number) {
		const minutes = number % 60;
		
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
		
		return formattedMinutes;
	}

	function convertToMinutes(hourString, minuteString) {
		const hours = hourString && hourString !== "" ? parseInt(hourString, 10) : 0;
		const minutes = minuteString && minuteString !== "" ? parseInt(minuteString, 10) : 0;
		
		return hours * 60 + minutes;
	}
	
	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const generalParams = {
			page: 1,
			limit: 100000,
		};
		const safrasParams = {
			...generalParams,
			filterValue: encodeURI(JSON.stringify({finalizada: false}))
		};

		const servicesInProperty = await propertyServiceService.get(propertyId, generalParams);
		setAllServices(getServicesDetails(servicesInProperty));
		const cropSeasonsInProperty = await cropSeasonService.get(propertyId, safrasParams);
		setAllSafras(getSafrasWithDetails(cropSeasonsInProperty));
		const inputsInProperty = await propertyInputsService.get(propertyId,generalParams);
		setAllInsumos(getInsumosWithDetails(inputsInProperty));
		const equipments = await applicationEquipmentService.get(generalParams);
		setAllEquipamentos(equipments);
		const applicationModes = await applicationModeService.get(generalParams);
		setAllModosAplicacao(applicationModes);
		const usersInProperty = await userService.getByProperty(propertyId);
		const operators = setOperators(usersInProperty);
		const managementId = searchParams.get("id");
		const toConfirm = searchParams.get("confirm");
		setFinalized(toConfirm === "s");

		if (managementId !== null) {
			setId(managementId);
			const management = await managementService.getById(managementId);
			if(!toConfirm) setFinalized(!management.pendente);
			setObservacao(management.observacao);
			if(management.propriedadeServicoId){
				const servicoId = servicesInProperty.findIndex(
					(serv) => serv.id === management.propriedadeServicoId
				);
				setSelectedService({
					title: servicesInProperty[servicoId].servico.nome,
					id: servicoId,
					index: servicoId,
				});
			}
			setOperatorsQuantity(management.quantidade_de_operadores);
			handleDateChange(getDateWithoutTimezone(management.dataAplicacao));
			setObservacao(management.observacao);
			const time = new Date(management.horario);
			const hours = time.getHours();
			const minutes = time.getMinutes();
			setvalue(management.horario ? `${hours  < 10 ? `0${hours}`: hours}${minutes < 10 ? `0${minutes}`: minutes}` : "");
			setHoras(convertToHoursText(management.duracaoMinutos));
			setMinutos(convertToMinutesText(management.duracaoMinutos));
			setQuantidadeTotal(management.quantidadeVolumeCalda);
			setAddVolumeCalda(
				management.quantidadeVolumeCalda !== null && management.quantidadeVolumeCalda > 0
			);
			const equipment = management.equipamentoAplicacao;
			if(equipment)
				setinputValueAplicacao(equipment.id);
			const applicationMode = management.modoAplicacao;
			if(applicationMode)
				setSelectedModoAplicacao(applicationMode.id);
			const userId = operators.findIndex((user) => user.id === management.responsavel);
			setSelectOperador({
				label: operators[userId].nome,
				id: userId,
				index: userId,
			});
			const cropSeason = await cropSeasonService.getById(management.safraId);
			AddExistentSafra(cropSeason, management.safra.propriedadeCultivo.cultivo);
			const produtos = management.propriedadeInsumos;
			setFirstVersionInput(produtos);
			AddExistentProdutos(produtos); 
		}
		setIsLoading(false);
	};

	const setOperators = (usersInProperty) => {
		const operators = [];
		if (usersInProperty && usersInProperty !== undefined) {
			for (let i = 0; i < usersInProperty.length; i++) {
				const userInProperty = usersInProperty[i];
				const user = userInProperty.usuario;
				if (user.ativo) operators.push({ id: user.id, nome: user.nome });
			}
		}
		setAllOperators(operators);
		return operators;
	};

	const AddExistentSafra = async (safra, cultivo) => {
		const talhao = safra.talhao;
		const newSafra = {
			id: safra.id,
			safra: safra,
			nome: `${talhao.nome} - ${captalizeFirstLetterEacheWord(cultivo.cultura)} ${captalizeFirstLetterEacheWord(cultivo.variedade ?? "")}`,
		};
		const newItemsSafra = [...itemsSafra, newSafra];
		setItemsSafra(newItemsSafra);
		// setSelectedSafra(safra);
	};

	const AddExistentProdutos = async (produtos) => {
		const newItemsProduto = [];
		for (let i = 0; i < produtos.length; i++) {
			const produto = produtos[i];
			const propertyInput = await propertyInputsService.getById(produto.propriedadeInsumoId);
			const measureUnit = propertyInput.unidadeMedida.sigla;
			const input = propertyInput.insumoEmGrupo.insumo;
			const newItemProduto = {
				id: produto.propriedadeInsumoId,
				manejoProdutoId: produto.id,
				insumo: input,
				quantity: produto.quantidade,
				custoUnitario: propertyInput.custoUnitario,
				unidadeMedida: measureUnit.toUpperCase(),
			};
			newItemsProduto.push(newItemProduto);
		}
		setItemsProduto(newItemsProduto);
		setSelectedProduto(null);
	};

	const getServicesDetails = (servicesInProperty) => {
		const services = [];
		for (let i = 0; i < servicesInProperty.length; i++) {
			const serviceInProperty = servicesInProperty[i];
			const service = serviceInProperty.servico;
			services.push({ id: serviceInProperty.id, service: service, custoUnitario: serviceInProperty.valorHora });
		}
		return services;
	};

	const getSafrasWithDetails = (safrasInProperty) => {
		const safras = [];
		for (let i = 0; i < safrasInProperty.length; i++) {
			const safra = safrasInProperty[i];
			const subDiv = safra.subdivisao;
			const cultivo = safra.propriedadeCultivo.cultivo;
			const catSub = subDiv?.categoriaSubdivisao;
			safras.push({
				id: safra.id,
				safra: safra,
				nome: `Talhão ${safra.talhao.nome}${
					subDiv !== null ? captalizeFirstLetterEacheWord(catSub) + " " + subDiv?.complemento : ""
				} - ${captalizeFirstLetterEacheWord(cultivo.cultura)} ${captalizeFirstLetterEacheWord(cultivo.variedade ?? "")}`,
			});
		}
		return safras;
	};

	const getInsumosWithDetails = (insumosInProperty) => {
		const insumos = [];
		for (let i = 0; i < insumosInProperty.length; i++) {
			const insumoInProperty = insumosInProperty[i];
			const insumo = insumoInProperty.insumoEmGrupo.insumo;
			const unidadeMedida = insumoInProperty.unidadeMedida;
			insumos.push({
				id: insumoInProperty.id,
				insumo: insumo,
				unidadeMedida: unidadeMedida,
				custoUnitario: insumoInProperty.custoUnitario,
			});
		}
		return insumos;
	};

	const handleAddSafra = () => {
		let newItemsSafra = [...itemsSafra];
		for (let j = 0; j < selected.length; j++) {
			let safra = "";
			for (let i = 0; i < allSafras.length; i++) {
				if (allSafras[i].id === selected[j]) {
					safra = allSafras[i];
					newItemsSafra = safra;
					itemsSafra.push(newItemsSafra);
					break;
				}
			}
		}
		return;
	};

	const handleRemoveSafra = (index) => {
		const newItems = itemsSafra.filter((item, itemIndex) => itemIndex !== index);
		setItemsSafra(newItems);
	};

	const handleAddProduto = () => {
		if (
			selectedProduto !== "" &&
			inputQuantidade !== "" &&
			selectedProduto !== undefined &&
			selectedProduto !== null
		) {
			const produto = allInsumos[selectedProduto.index];
			const newItemProduto = {
				id: produto.id,
				insumo: produto.insumo,
				quantity: inputQuantidade,
				custoUnitario: produto.custoUnitario,
				unidadeMedida: selectedProduto.unidadeMedida.toUpperCase(),
			};
			const newItemsProduto = [...itemsProduto, newItemProduto];

			setItemsProduto(newItemsProduto);
			setSelectedProduto(null);
			setInputQuantidade("");
		}
	};
	const handleRemoveProduto = (index) => {
		const newItems = itemsProduto.filter(
			(item, itemIndex) => itemIndex !== index
		);

		setItemsProduto(newItems);
	};

	const handleOpenSafra = () => {
		setOpenSafra(true); //abre o modal
	};

	const handleCloseSafra = () => {
		setSelectedProduto(null);
		setInputQuantidade("");
		setOpenSafra(false); //fecha o modal
	};

	const handleOpenProduto = () => {
		setOpenProduto(true); //abre o modal
	};

	const handleCloseProduto = () => {
		setOpenProduto(false); //fecha o modal
	};

	const handleCloseAddSafra = () => {
		if(id && selected.length > 1){
			setTypeMessage("error");
			setMessage("Só é possivel adicionar uma safra na edição");
			setErrorMessageSafra(true);
		}else {
			handleAddSafra(); //adiciona o item no array
			setOpenSafra(false); //fecha o modal
		}
	};
	const handleCloseAddProduto = () => {
		handleAddProduto(); //adiciona o item no array
		setOpenProduto(false); //fecha o modal
	};

	const validationSave = async () => {
		if (selectedDate && selectedService && selectOperador && itemsSafra.length > 0) {
			if (addVolumeCalda === true) {
				if (
					inputValueAplicacao !== "" &&
					quantidadeTotal !== "" &&
					selectedModoAplicacao !== "-1"
				) {
					await handleSave();
					setTypeMessage("success");
					setMessage("cadastro salvo!!!");
					setErrorMessageQuantidadeTotal(false);
					setErrorMessageModoAplicacao(false);
					setErrorMessageService(false);
					setErrorMessageOperator(false);
					setErrorMessageEquipamentoApli(false);
					setErrorMessageAplicação(false);
					setErrorMessageDataAplicacao(false);
					setErrorMessageSafra(false);
					return true;
				} else {
					let message = "Um ou mais campos vazios! - (";
					if (quantidadeTotal === "0" || quantidadeTotal === "") {
						message += "Volume de calda, ";
						setErrorMessageQuantidadeTotal(true);
					} else {
						setErrorMessageQuantidadeTotal(false);
					}
					if (Number(selectedModoAplicacao) === -1) {
						message += "Selecionar modo de aplicação, ";
						setErrorMessageModoAplicacao(true);
					} else {
						setErrorMessageModoAplicacao(false);
					}
					if (Number(inputValueAplicacao) === -1) {
						message += "Selecionar equipamento de aplicação, ";
						message += ")";
						setErrorMessageEquipamentoApli(true);
					} else {
						setErrorMessageEquipamentoApli(false);
					}
					setTypeMessage("error");
					setMessage(message);
					return false;
				}
			} else {
				await handleSave();
				setTypeMessage("success");
				setMessage("cadastro salvo!!!");
				setErrorMessageAplicação(false);
				setErrorMessageDataAplicacao(false);
				setErrorMessageSafra(false);
				return true;
			}
		} else {
			let message = "Um ou mais campos vazios! - (";

			if (!selectedService) {
				message += "Serviço, ";
				setErrorMessageService(true);
			} else {
				setErrorMessageService(false);
			}
			if (!selectOperador) {
				message += "Operador, ";
				setErrorMessageOperator(true);
			} else {
				setErrorMessageOperator(false);
			}
			if (!selectedDate) {
				message += "Data, ";
				setErrorMessageDataAplicacao(true);
			} else {
				setErrorMessageDataAplicacao(false);
			}
			if (itemsSafra.length === 0) {
				message += "Adicionar safras, ";
				setErrorMessageSafra(true);
			} else {
				setErrorMessageSafra(false);
			}
			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};

	const handleSave = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		const scheduleDate = new Date();
		value !== null && value !== "" && scheduleDate.setHours(parseInt(`${value.substring(0, 2)}`), parseInt(`${value.substring(2, 4)}`));
		if (!id) {
			const includeCropSeason = [];
			itemsSafra.map((safra) => {
				includeCropSeason.push(safra.id);
			});
			const includeInput = [];
			itemsProduto.map((itemProduto) => {
				includeInput.push({
					propertyInputId: itemProduto.id,
					quantity: Number(itemProduto.quantity),
					value: itemProduto.custoUnitario,
				});
			});
			
			const managementToSave = {
				operatorsNumber: operatorsQuantity,
				observation: document.getElementById("observacao").value,
				requester: user.userId,
				responsible: selectOperador && selectOperador !== null
					? allOperators[selectOperador.index].id
					: user.userId,
				servicePropertyId: selectedService
					? allServices[selectedService.index].id
					: null,
				applicationEquipmentId: inputValueAplicacao !== null && Number(inputValueAplicacao) !== -1
					? Number(inputValueAplicacao)
					: null,
				pending: !finalized,
				minutesDuration: (horas && horas !== "") || (minutos && minutos !== "") ? 
					Number(convertToMinutes(horas?.toString(), minutos?.toString())) : null,
				serviceValue: selectedService ? allServices[selectedService.index].custoUnitario : 0,
				solutionVolumeAmount: quantidadeTotal !== "" ? Number(quantidadeTotal) : null,
				solicitationDate: new Date(),
				applicationDate: selectedDate,
				schedule: value !== null && value !== ""
					? scheduleDate
					: null,
				applicationModeId: selectedModoAplicacao !== null && Number(selectedModoAplicacao) !== -1
					? Number(selectedModoAplicacao)
					: null,
				includeCropSeason,
				includeInput
			};
			await managementService.save(managementToSave);
			return;
		}
		const removeInput = [];
		const includeInput = [];
		firstVersionInput.forEach((input) => {
			removeInput.push(input.propriedadeInsumoId);
		});
		itemsProduto.forEach((itemProduto) => {
			includeInput.push({
				propertyInputId: itemProduto.id,
				quantity: Number(itemProduto.quantity),
				value: itemProduto.custoUnitario,
			});
		});

		const cropSeason = itemsSafra[0];
		
		const managementToEdit = {
			id,
			operatorsNumber: operatorsQuantity,
			observation: document.getElementById("observacao").value,
			requester: user.userId,
			responsible: selectOperador && selectOperador !== null
				? allOperators[selectOperador.index].id
				: user.userId,
			servicePropertyId: selectedService
				? allServices[selectedService.index].id
				: null,
			applicationEquipmentId: inputValueAplicacao !== null && Number(inputValueAplicacao) !== -1
				? Number(inputValueAplicacao)
				: null,
			pending: !finalized,
			minutesDuration: (horas && horas !== "") || (minutos && minutos !== "") ? 
				Number(convertToMinutes(horas?.toString(), minutos?.toString())) : null,
			serviceValue: selectedService ? allServices[selectedService.index].custoUnitario : 0,
			solutionVolumeAmount: quantidadeTotal !== "" ? Number(quantidadeTotal) : null,
			applicationDate: selectedDate,
			schedule: value !== null && value !== ""
				? scheduleDate
				: null,
			applicationModeId: selectedModoAplicacao !== null && Number(selectedModoAplicacao) !== -1
				? Number(selectedModoAplicacao)
				: null,
			cropSeasonId: cropSeason.id,
			includeInput,
			removeInput
		};
		await managementService.update(managementToEdit);
		
	};

	function handleChange(event) {
		setvalue(event.target.value);
	}
	const dataAllServices = allServices.map((item, index) => {
		return {
			title: item.service.nome,
			id: index,
			index: index,
		};
	});
	const optionsAllServices = dataAllServices.map((option) => {
		const firstLetter = option.title[0].toUpperCase();
		return {
			firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
			...option,
		};
	});
	const dataAllOperators = allOperators.map((item, index) => {
		return {
			label: item.nome,
			id: index,
			index: index,
		};
	});
	const dataAllInsumos = allInsumos.map((item, index) => {
		return {
			title: item.insumo.nome,
			id: index,
			index: index,
			unidadeMedida: item.unidadeMedida.sigla,
		};
	});
	const optionsAllInsumos = dataAllInsumos.map((option) => {
		const firstLetter = option.title[0].toUpperCase();
		return {
			firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
			...option,
		};
	});
	
	const handleDateChange = (date) => {
		setSelectedDate(new Date(date));
	};
	const handleClick = (e, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};
	const isSelected = (name) => selected.indexOf(name) !== -1;
	const FormatDatePtBR = (date) => {
		let data = date.split("-");
		return `${data[2]}/${data[1]}/${data[0]}`;
	};
	const dataAllSafras = allSafras.map((item, index) => {
		return {
			id: item.id,
			nome: item.nome,
			data_plantio: FormatDatePtBR(item.safra.dataPlantio.split("T")[0]),
			index: index,
		};
	});

	const getTotalDuration = () => {
		const durationInMinutes = Number(convertToMinutes(horas?.toString(), minutos?.toString()));
		const totalDuration = operatorsQuantity * durationInMinutes;
		const totalHours = Math.floor(totalDuration / 60);
		const totalMinutes = totalDuration % 60;

		const minutesAsString = totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes;

		return `${totalHours}:${minutesAsString}`;
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //paginação
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //paginação
		setPage(0);
	};
	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};
	function descendingComparator(a, b, orderBy) {

		if (orderBy === "data_plantio") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}
		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRowsAtivo = useMemo(
		() =>
			stableSort(dataAllSafras, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage,dataAllSafras]
	);

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu
					open={["launch", "management"]}
					help={helpIdFinter === null ? "managementCad" : "managementEdit"}
				/>
				<Box component="main" sx={{ p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
							<Grid item xs={12} sm={12} md={12}>
								<Item>
									<RegisterArea
										title={helpIdFinter === null ? "Registro de manejo" : "Editar manejo"}
										href="/manejo"
										dataCriacao={true}
										dataAlteracao={true}
										salveFunc={validationSave}
										typeMessage={typeMessage}
										message={message}
									>
										{/* inputs da pagina de resgitro */}
										<Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
											<Grid item xs={12} sm={12} md={3}>
												<div
													className={
														errorMessageService ? styles.errControl : styles.noErrControl
													}
												>
													<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedService === null?"hidden":"visible"),transition:"all 0.5s ease"}} >
															Serviço *
														</Typography>
														<ThemeProvider theme={theme}>
															<Autocomplete
																options={optionsAllServices.sort(
																	(a, b) => -b.firstLetter.localeCompare(a.firstLetter)
																)}
																groupBy={(option) => option.firstLetter}
																getOptionLabel={(option) => option.title}
																sx={{ width: "100%", border: "none" }}
																value={selectedService === undefined ? "" : selectedService}
																onChange={(event, newValue) => {
																	setSelectedService(newValue);
																}}
																renderInput={(params) => (
																	<div ref={params.InputProps.ref}>
																		<input
																			type="text"
																			{...params.inputProps}
																			placeholder="Serviço *"
																			className={styles.inputManagement}
																		/>
																	</div>
																)}
															/>
														</ThemeProvider>
													</Box>
												</div>
											</Grid>
											<Grid item xs={12} sm={6} md={2}>
												<div
													className={
														errorMessageAplicação ? styles.errControl : styles.noErrControl
													}
												>	<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography variant="caption" display="block" gutterBottom sx={{top:-20,position:"absolute",px:1,backgroundColor:"white",visibility:(value === ""?"hidden":"visible"),transition:"all 0.5s ease"}} >
														Hora da aplicação
														</Typography>
														<InputMask
															placeholder="Hora da aplicação"
															mask="99:99"
															value={value}
															onChange={handleChange}
															class={styles.inputManagement}
														/>
														<p
															className={
																errorMessageAplicação ? styles.errText : styles.noErrText
															}
														>
														*
														</p>
													</Box>
												</div>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",transition:"all 0.5s ease"}} >
														Duração da aplicação
													</Typography>
													<Box	Box sx={{
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "row",
														alignItems: "flex-start",
														position: "relative",
														m:0
													}}>
														<Grid item xs={5} sm={5} md={5}>
															<input
																type="number"
																id="horas"
																placeholder="Horas"
																value={horas}
																onChange={(e) => setHoras(e.target.value)}
																className={styles.inputManagement}
																min={0}
															/>
														</Grid>
														<Grid item xs={2} sm={2} md={2} sx={{paddingTop: 2}}>
															<Typography variant="caption" gutterBottom sx={{px:1}} >
																:
															</Typography>
														</Grid>
														<Grid item xs={5} sm={5} md={5}>
															<input
																type="number"
																id="minutos"
																placeholder="Minutos"
																value={minutos}
																onChange={(e) => setMinutos(e.target.value)}
																className={styles.inputManagement}
																min={1}
																max={59}
															/>
														</Grid>
													</Box>
												</Box>
											</Grid>
											<Grid item xs={12} sm={6} md={2}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-20,position:"absolute",px:1,backgroundColor:"white",visibility:(operatorsQuantity === ""?"hidden":"visible"),transition:"all 0.5s ease"}} >
														Número de operadores
													</Typography>
													<input
														placeholder="Número de operadores"
														id="operatorsNumber"
														value={operatorsQuantity}
														onChange={e=> setOperatorsQuantity(Number(e.target.value))}
														className={styles.inputManagement}
														type="number"
														min={1}
													/>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={2}>
												<Box	Box sx={{
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Grid 
														container
														direction="column"
														alignItems="center"
														justifyContent="center"
														item xs={12} sm={12} md={12}>
														<Typography variant="caption" display="block" gutterBottom sx={{px:1,backgroundColor:"white",visibility:"visible",transition:"all 1s ease"}} >
															Duração total
														</Typography>
													</Grid>
													<Grid 
														container
														direction="column"
														alignItems="center"
														justifyContent="center"
														item xs={12} sm={12} md={12}>
														<Typography variant="caption" display="block" gutterBottom sx={{px:1,backgroundColor:"white",visibility:"visible",transition:"all 1s ease", align: "center"}} >
															{getTotalDuration()}
														</Typography>
													</Grid>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={2}>
												<div
													className={
														errorMessageDataAplicacao ? styles.errControl : styles.noErrControl
													}
												>	<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedDate ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
															Data *
														</Typography>
														<InputData placeholder="Data *" value={selectedDate ? new Date(selectedDate).toLocaleDateString("pt-BR") : null} onDateChange={handleDateChange} classN={styles.inputManagement}/>

														<p
															className={
																errorMessageDataAplicacao ? styles.errText : styles.noErrText
															}
														>
														*
														</p>
													</Box>
												</div>
											</Grid>
											<Grid item xs={12} sm={12} md={5}>
												<div
													className={
														errorMessageOperator ? styles.errControl : styles.noErrControl
													}
												>
													<Box	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectOperador === null?"hidden":"visible"),transition:"all 0.5s ease"}} >
															Selecionar operador *
														</Typography>
														<ThemeProvider theme={theme}>
															<Autocomplete
																options={dataAllOperators}
																sx={{ width: "100%", border: "none" }}
																value={selectOperador === null ? "" : selectOperador}
																onChange={(event, newValue) => {
																	setSelectOperador(newValue);
																}}
																renderInput={(params) => (
																	<div ref={params.InputProps.ref}>
																		<input
																			type="text"
																			{...params.inputProps}
																			placeholder="Selecionar operador *"
																			className={styles.inputManagement}
																		/>
																	</div>
																)}
															/>
														</ThemeProvider>
													</Box>
												</div>
											</Grid>
											<Grid item xs={12} sm={12} md={5}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(observacao === ""?"hidden":"visible"),transition:"all 0.5s ease"}} >
														Observação
													</Typography>
													<input type="text" placeholder="Observação" id="observacao" className={styles.inputManagement} value={observacao }  onChange={e=>setObservacao(e.target.value)}/>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>										
												<div className={styles.registrationTitle}>
													<a onClick={id && itemsSafra.length > 0 ? null : handleOpenSafra} className={styles.addBtn}>
															Adicionar Safras *
														<AddIcon style={styleAdd} />
													</a>
												</div>
												<p
													className={errorMessageSafra ? styles.errText : styles.noErrText}
												>
														Adicione no minimo 1 safra *
												</p>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={{ minHeight: "45px" }}>
													<Grid
														container
														rowSpacing={0}
														columnSpacing={{ xs: 0, sm: 0, md: 0 }}
														sx={{
															display: "flex",
															alignItems: "center",
															justifyContent: "start",
															width: "100%",
														}}
													>
														{itemsSafra.map((item, index) => (
															<Grid item key={index}>
																<Item sx={{ border: "none", boxShadow: "none" }}>
																	<div className={styles.itemNameCuture}>
																		<button
																			className={styles.itemButtonCuture}
																			type="button"
																			id={item.id}
																			onClick={() => handleRemoveSafra(index)}
																		>
																			X
																		</button>
																		{item.nome}
																	</div>
																</Item>
															</Grid>
														))}
													</Grid>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<div className={styles.registrationTitle}>
														<a onClick={handleOpenProduto} className={styles.addBtn}>
															Adicionar Produtos
															<AddIcon style={styleAdd} />
														</a>
													</div>

													<HelpButtonInput
														title={"Adicionar produtos "}
														text={
															"Selecione o produto que será aplicado. Se for uma mistura de produtos, repita o processo de adição para todos produtos a serem utilizados na calda."
														}
													/>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={{ minHeight: "45px" }}>
													<Grid
														container
														rowSpacing={0}
														columnSpacing={{ xs: 0, sm: 0, md: 0 }}
														sx={{
															display: "flex",
															alignItems: "center",
															justifyContent: "start",
															width: "100%",
														}}
													>
														{itemsProduto.map((item, index) => (
															<Grid item key={index}>
																<Item sx={{ border: "none", boxShadow: "none" }}>
																	<div className={styles.itemNameCuture}>
																		<button
																			className={styles.itemButtonCuture}
																			type="button"
																			id={item.id}
																			onClick={() => handleRemoveProduto(index)}
																		>
																			X
																		</button>
																		{item.insumo.nome + " - " + item.quantity + " " +item.unidadeMedida}
																	</div>
																</Item>
															</Grid>
														))}
													</Grid>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>										
												<div className={styles.checkboxContainer}>
													<input
														checked={addVolumeCalda}
														onChange={() => setAddVolumeCalda(!addVolumeCalda)}
														className={styles.inputCheckbox}
														type="checkbox"
														id="volumeCalda"
														name="volumeCalda"
													/>
													<label htmlFor="volumeCalda">
															Adicionar volume de calda aos produtos
													</label>
													<HelpButtonInput
														title={"ADICIONAR VOLUME DE CALDA"}
														text={
															"Se a sua aplicação for em calda, informe ao sistema clicando na caixa."
														}
													/>
												</div>
											</Grid>
											{addVolumeCalda && (
												<Grid item xs={11} sm={11} md={3}>
													<div
														className={
															errorMessageQuantidadeTotal
																? styles.errControl
																: styles.noErrControl
														}
													>	<Box	Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(quantidadeTotal === ""?"hidden":"visible"),transition:"all 0.5s ease"}} >
																Volume de calda
															</Typography>
															<input
																type="number"
																placeholder="Volume de calda"
																value={quantidadeTotal}
																disabled={!addVolumeCalda}
																onChange={(event) => setQuantidadeTotal(event.target.value)}
																className={styles.inputManagement}
															/>
															<p
																className={
																	errorMessageQuantidadeTotal ? styles.errText : styles.noErrText
																}
															>
															*
															</p>
														</Box>
													</div>
												</Grid>
											)}
											{addVolumeCalda && (
												<Grid item xs={1} sm={1} md={1}>
													<HelpButtonInput
														title={"VOLUME DE CALDA "}
														text={"Especifique a quantidade total de calda que será aplicada."}
													/>
												</Grid>
											)}
											{addVolumeCalda && (
												<Grid item xs={12} sm={12} md={4}>
													<div
														className={
															errorMessageEquipamentoApli
																? styles.errControl
																: styles.noErrControl
														}
													>
														<Box	Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(inputValueAplicacao === "-1"?"hidden":"visible"),transition:"all 0.5s ease"}} >
															Selecionar equipamento de aplicação
															</Typography>
															<select
																disabled={!addVolumeCalda}
																value={inputValueAplicacao}
																onChange={(event) => setinputValueAplicacao(event.target.value)}
																onKeyPress={(e) => {
																	e.key === "Enter" && e.preventDefault();
																}}
																className={styles.inputManagement}
															>
																<option value={"-1"}>
																	{Number(inputValueAplicacao) !== -1
																		? "---------------"
																		: "Selecionar equipamento de aplicação"}
																</option>
																{allEquipamentoss.map((item, index) => {
																	return (
																		<option value={item.id} key={index}>
																			{item.nome}
																		</option>
																	);
																})}
															</select>
															<p
																className={
																	errorMessageEquipamentoApli ? styles.errText : styles.noErrText
																}
															>
															*
															</p>
														</Box>
													</div>
												</Grid>
											)}
											{addVolumeCalda && (
												<Grid item xs={12} sm={12} md={4}>
													<div
														className={
															errorMessageModoAplicacao ? styles.errControl : styles.noErrControl
														}
													>
														<Box	Box sx={{
															border: "1px solid rgba(0,0,0,0.3)",
															borderRadius: "8px",
															width: "100%",
															display: "flex",
															justifyContent: "flex-start",
															flexDirection: "column",
															alignItems: "flex-start",
															px: 1,
															pr:2,
															position: "relative",
															m:0
														}}>
															<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedModoAplicacao === "-1" ?"hidden":"visible"),transition:"all 0.5s ease"}} >
																Selecionar modo de aplicação
															</Typography>
															<select
																disabled={!addVolumeCalda}
																onChange={(event) => setSelectedModoAplicacao(event.target.value)}
																onKeyPress={(e) => {
																	e.key === "Enter" && e.preventDefault();
																}}
																className={styles.inputManagement}
																value={selectedModoAplicacao}
															>
																<option value={"-1"}>
																	{Number(selectedModoAplicacao) !== -1
																		? "---------------"
																		: "Selecionar modo de aplicação"}
																</option>
																{allModosAplicacao.map((item, index) => {
																	return (
																		<option value={item.id} key={index}>
																			{item.nome}
																		</option>
																	);
																})}
															</select>
															<p
																className={
																	errorMessageModoAplicacao ? styles.errText : styles.noErrText
																}
															>
															*
															</p>
														</Box>
													</div>
												</Grid>
											)}
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<div className={styles.checkboxContainer}>
														<input
															className={styles.inputCheckbox}
															type="checkbox"
															id="checkBoxManejo"
															name="checkBoxManejo"
															value="manejo"
															checked={finalized}
															onClick={() => setFinalized(!finalized)}
														/>
														<label htmlFor="checkBoxManejo">Manejo realizado</label>
													</div>
												</Item>
											</Grid>
										</Grid>
									</RegisterArea>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Modal
					open={openSafra}
					onClose={handleCloseSafra}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				>
					<Box
						sx={{
							...style,
							width: { xs: "90%", sm: "50%", md: "50%" },
							bgcolor: "white",
							borderRadius: "8px",
							height: "max-content",
							maxHeight: "70%",
							p: 3,
						}}
					>
						<Grid
							container
							rowSpacing={3}
							columnSpacing={{ xs: 0, sm: 2, md: 3 }}
							sx={stylesItem}
							className={styles.registrationInputModal}
						>
							<Grid item xs={12} sm={12} md={12}>
								<p className={styles.registrationTitle}>
									Safra
								</p>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Item sx={stylesItem}>
									<Paper sx={{ width: "100%", p: 0, m: 0 }}>
										<ThemeProvider theme={theme}>
											<p style={{color: "red"}}>
												{errorMessageSafra ? message: ""}
											</p>
											<TableContainer sx={{ maxHeight: "45vh" }}>
												<Table stickyHeader>
													<EnhancedTableHead
														numSelected={selected.length}
														order={order}
														orderBy={orderBy}
														onSelectAllClick={handleSelectAllClick}
														onRequestSort={handleRequestSort}
														rowCount={dataAllSafras.length}
														columns={columnForActive}
													/>
											
													<TableBody>
														{
															//cria uma linha por objeto
															visibleRowsAtivo
																.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
																.map((row, index) => {
																	const isItemSelected = isSelected(row.id);
																	return (
																		<TableRow
																			hover
																			onClick={(event) => handleClick(event, row.id)}
																			role="checkbox"
																			aria-checked={isItemSelected}
																			tabIndex={-1}
																			key={index}
																		>
																			<TableCell padding="checkbox">
																				<Checkbox color="success" checked={isItemSelected} />
																			</TableCell>
																			{keysForActive.map((column) => {
																				return <TableCell key={column}>{row[column.id]}</TableCell>;
																			})}
																		
																		</TableRow>
																	);
																})
														}
													</TableBody>
												</Table>
											</TableContainer>
											<TablePagination
												rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
												component="div"
												colSpan={7}
												count={visibleRowsAtivo.length}
												rowsPerPage={rowsPerPage}
												page={page}
												labelRowsPerPage="Linhas por pagina"
												SelectProps={{
													inputProps: {
														"aria-label": "rows per page",
													},
													native: false,
												}}
												onPageChange={handleChangePage}
												onRowsPerPageChange={handleChangeRowsPerPage}
												ActionsComponent={TablePaginationActions}
											/>
										</ThemeProvider>
									</Paper>
								</Item>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Item sx={stylesItem}>
									<Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 0, sm: 0, md: 0 }}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "end",
											flexDirection: {
												xs: "column-reverse",
												sm: "column-reverse",
												md: "row",
											},
										}}
									>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={stylesItem}>
												<button
													type="button"
													onClick={handleCloseSafra}
													className={styles.registrationButtonCancelar}
												>
													Cancelar
												</button>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={stylesItem}>
												<button
													className={styles.registrationButtoncadastrar}
													type="button"
													onClick={handleCloseAddSafra}
												>
													Cadastrar
												</button>
											</Item>
										</Grid>
									</Grid>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Modal>

				<Modal
					open={openProduto}
					onClose={handleCloseProduto}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				>
					<Box
						sx={{
							...style,
							width: { xs: "90%", sm: "50%", md: "50%" },
							bgcolor: "white",
							borderRadius: "8px",
							p: 3,
						}}
					>
						<Grid
							container
							rowSpacing={3}
							columnSpacing={{ xs: 0, sm: 2, md: 3 }}
							sx={stylesItem}
							className={styles.registrationInputModal}
						>
							<Grid item xs={12} sm={12} md={12}>
								<p className={styles.registrationTitle}>
									Produto
								</p>
							</Grid>
							<Grid item xs={12} sm={12} md={5}>
								<Box	Box sx={{
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr:2,
									position: "relative",
									m:0
								}}>
									<Typography variant="caption" display="block" gutterBottom sx={{top:-25 ,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedProduto === null ?"hidden":"visible"),transition:"all 1s ease"}} >
										Produto
									</Typography>
									<ThemeProvider theme={theme}>
										<Autocomplete
											options={optionsAllInsumos.sort(
												(a, b) => -b.firstLetter.localeCompare(a.firstLetter)
											)}
											groupBy={(option) => option.firstLetter}
											getOptionLabel={(option) => option.title}
											sx={{
												width: "100%",
											}}
											value={selectedProduto === undefined ? "" : selectedProduto}
											onChange={(event, newValue) => {
												setSelectedProduto(newValue);
											}}
											renderInput={(params) => (
												<div ref={params.InputProps.ref}>
													<input type="text" {...params.inputProps} placeholder="Produto" />
												</div>
											)}
										/>
									</ThemeProvider>
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={3}>
								<Box	Box sx={{
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr:2,
									position: "relative",
									m:0
								}}>
									<Grid 
										container
										direction="column"
										alignItems="center"
										justifyContent="center"
										item xs={12} sm={12} md={12}>
										<Typography variant="caption" display="block" gutterBottom sx={{px:1,backgroundColor:"white",visibility:"visible",transition:"all 1s ease"}} >
											Unidade de medida
										</Typography>
									</Grid>
									<Grid 
										container
										direction="column"
										alignItems="center"
										justifyContent="center"
										item xs={12} sm={12} md={12}>
										<Typography variant="caption" display="block" gutterBottom sx={{px:1,backgroundColor:"white",visibility:"visible",transition:"all 1s ease", align: "center"}} >
											{selectedProduto?.unidadeMedida.toUpperCase()}
										</Typography>
									</Grid>
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={4}>
								<Box	Box sx={{
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr:2,
									position: "relative",
									m:0
								}}>
									<Typography variant="caption" display="block" gutterBottom sx={{top:-25 ,position:"absolute",px:1,backgroundColor:"white",visibility:(inputQuantidade === "" ?"hidden":"visible"),transition:"all 1s ease"}} >
										Quantidade
									</Typography>
									<input
										type="number"
										placeholder="Quantidade"
										value={inputQuantidade >= 0 ? inputQuantidade : ""}
										onChange={(event) => setInputQuantidade(event.target.value)}
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Item sx={stylesItem}>
									<Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 0, sm: 0, md: 0 }}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "end",
											flexDirection: {
												xs: "column-reverse",
												sm: "column-reverse",
												md: "row",
											},
										}}
									>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={stylesItem}>
												<button
													type="button"
													onClick={handleCloseProduto}
													className={styles.registrationButtonCancelar}
												>
													Cancelar
												</button>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={stylesItem}>
												<button
													className={styles.registrationButtoncadastrar}
													type="button"
													onClick={handleCloseAddProduto}
												>
													Cadastrar
												</button>
											</Item>
										</Grid>
									</Grid>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			</div>
		);
}

export default ManagementCad;
