import { LossRepository } from "../repository/loss_repository";

export class LossService {

	constructor() {
		this.lossRepository = new LossRepository();
	}

	async save(loss) {
		const savedLoss = await this.lossRepository.save(loss);
		return savedLoss;
	}

	async update(loss) {
		const updatedLoss = await this.lossRepository.update(loss);
		return updatedLoss;
	}

	async getByProperty(propertyId, params) {
		const lossData = await this.lossRepository.getByProperty(propertyId, params);
		return lossData.losses;
	}

	async getAllLossType() {
		const lossData = await this.lossRepository.getAllLossTypes();
		return lossData;
	}

	async getById(lossId) {
		const loss = await this.lossRepository.getById(lossId);
		return loss;
	}

	async changeVisibility(id) {
		await this.lossRepository.changeVisibility(id);
	}

	async delete(lossId) {
		const loss = await this.lossRepository.delete(lossId);
		return loss;
	}
}