import { OrderRepository } from "../repository/order_repository";

export class OrderService {

	constructor() {
		this.orderRepository = new OrderRepository();
	}

	async save(order) {
		const savedOrder = await this.orderRepository.save(order);
		return savedOrder;
	}

	async saveItem(orderId, order) {
		const savedOrder = await this.orderRepository.saveItem(orderId, order);
		return savedOrder;
	}

	async updateItem(orderId, orderItem) {
		const savedOrder = await this.orderRepository.updateItem(orderId, orderItem);
		return savedOrder;
	}

	async getByProperty(propertyId, params) {
		const orderData = await this.orderRepository.getByProperty(propertyId, params);
		return orderData.orders;
	}

	async getOrder(id) {
		return await this.orderRepository.getOrder(id);
	}

	async getOrderItem(id) {
		return await this.orderRepository.getOrderItem(id);
	}

	async delete(id) {
		return await this.orderRepository.delete(id);
	}
	async deleteItem(id) {
		return await this.orderRepository.deleteItem(id);
	}

	async updateItemAsReturned(item) {
		const savedOrder = await this.orderRepository.updateItemAsReturned(item);
		return savedOrder;
	}

	async changeVisibility(id) {
		await this.orderRepository.changeVisibility(id);
	}

	async changeVisibilityItem(id) {
		await this.orderRepository.changeVisibilityItem(id);
	}
}