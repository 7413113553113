import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useMemo, useState } from "react";
// import TableModal from "../../tableModalColheita";
import "./style.css";
import styles from "./TableHarvestDemand.module.css";

//import material-ui
import AddIcon from "@mui/icons-material/Add";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableSortLabel, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { ptBR } from "@mui/material/locale";
import Paper from "@mui/material/Paper";
import { createTheme, styled, ThemeProvider, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import HelpButtonInput from "../../HelpButtonInput";
import InputData from "../../InputData";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";
import { CropSeasonService } from "../../../service/crop_season_service";
import { HarvestService } from "../../../service/harvest_service";
const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);

// import dataModal from "../../../dataTests/dataColheitaModal.json";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "stretch",
	justifyContent: "stretch",
	widths: "auto",
	m: 0,
	p: 0,
};
const style = {
	bgcolor: "red",
	position: "absolute",
	top: "50%",
	left: "50%",
	width: 100,
	transform: "translate(-50%, -50%)",
};
const styleAdd = {
	color: "#1A877F",
	margin: "0 0 0 5px",
};

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};
// const keys = ["cuture", "quant", "unid", "data_solicitacao"];
// const columns = [
// 	"Cultura",
// 	"Quantidade",
// 	"Unidade de Medida",
// 	"Data de solicitação",
// ];
const headCells = [
	{
		id: "cuture",
		label: "Cultura",
	},
	{
		id: "quant",
		label: "Quantidade",
	},
	{
		id: "unid",
		label: "Unidade de Medida",
	},
	{
		id: "data_solicitacao",
		label: "Data de solicitação",
	},
];

function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort } =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const keys = ["cuture", "quant", "unid", "data_solicitacao"];

const columnForActive = {
	nome: "Nome",
	data_plantio: "Data Plantio",
	quantidade: "Quantidade",
	unid: "Unidade de Medida",
};

function HarvestDemand(props) {
	const [page, setPage] = useState(0); //page tabela
	const [rowsPerPage, setRowsPerPage] = useState(7); //rows per page
	const [openColher, setOpenColher] = useState(false); //modal
	const [openSafra, setOpenSafra] = useState(false);
	const [culture, setCulture] = useState("");

	const [itemsSafra, setItemsSafra] = useState([]);
	const [allSafras, setAllSafras] = useState([]);
	const [selectedLote, setSelectedLote] = useState(null);
	const [date, setDate] = useState(null);
	const [msgError, setMsgError] = useState("");
	const [validationError, setValidationError] = useState(false);
	const [selected, setSelected] = useState([]);
	const keysForActive = Object.keys(columnForActive); //array de chaves
	const columnsForActive = Object.values(columnForActive); //array de valores
	const [errorAddSafra, setErrorAddSafra] = useState(false);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("cuture");

	const cropSeasonService = new CropSeasonService();
	const harvestService = new HarvestService();
	
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = dataFilter.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const getSafrasWithDetails = async (safras) => {
		const safrasWithDetails = [];
		for (let i = 0; i < safras.length; i++) {
			const safra = safras[i];
			const cultivoInProperty = safra.propriedadeCultivo;
			const talaho = safra.talhao;
			const cultivo = cultivoInProperty.cultivo;
			safrasWithDetails.push({
				id: safra.id,
				safra: safra,
				nome: `Talhão ${talaho.nome} ${
					safra.subdivisao ? captalizeFirstLetterEacheWord(safra.subdivisao?.categoriaSubdivisao) + " " + safra.subdivisao?.complemento : ""
				}- ${captalizeFirstLetterEacheWord(cultivo.cultura)} ${captalizeFirstLetterEacheWord(cultivo.variedade ?? "")}`,
			});
		}
		return safrasWithDetails;
	};

	const handleDateChange = (date) => {
		setDate(new Date(date));
	};

	const handleOpenColher = async (e) => {
		const propertyId = localStorage.getItem("propertyId");		
		const data = dataFilter.filter(i => i.id === e.target.id);
		setCulture(data[0].cuture);
		setSelectedLote(data[0].itemId);
		const generalParams = {
			page: 1,
			limit: 100000,
		};
		const cropSeasonParams = {
			...generalParams,
			filterValue: encodeURI(
				JSON.stringify(
					{
						finalizada: false,
						propriedadeCultivoId: data[0].id_cuture
					}
				)
			)
		};
		const cropSeasonsInProperty = await cropSeasonService.get(propertyId, cropSeasonParams);
		setAllSafras(await getSafrasWithDetails(cropSeasonsInProperty));
		setOpenColher(true); //abre o modal
	};
	const handleCloseColher = () => {
		setOpenColher(false); //fecha o modal
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //seta a pagina atual tabela
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //seta a quantidade de linhas por pagina
		setPage(0); //seta a pagina atual tabela
	};

	const dataFilter = props.data.filter(
		(item) => item.cuture.toLowerCase().includes(props.search.toLowerCase())
		|| item.quant.toString().includes(props.search.toLowerCase()) 
		|| item.unid.toLowerCase().includes(props.search.toLowerCase()) 
		|| item.data_solicitacao.toString().includes(props.search.toLowerCase()) 
	);
	const handleCloseBtnAdd = async () => {
		if (date && itemsSafra.length > 0) {
			await handleSave(); //adiciona o item no array
			handleCloseColher(); //fecha o modal
			setMsgError("");
			setValidationError(false);
			window.location.reload();
		} else {
			let message = "Um ou mais campos vazios! - (";
			if (date.length !== 10)
				message += "Data de colheita, ";
			if (itemsSafra.length === 0) message += "Adicionar safra...";
			message += ")";
			setMsgError(message);
			setValidationError(!validationError);
		}
	};

	const handleSave = async () => {
		const harvest = await harvestService.getById(selectedLote);
		const cropSeasons = [];
		itemsSafra.forEach(safra => {
			cropSeasons.push({
				cropSeasonId: safra.itemId,
				amount: Number(safra.itemQuantity),
			});
		});
		const harvestToUpdate = {
			id: selectedLote,
			farmId: harvest.propriedadeCultivoId,
			packagingId: harvest.embalagemId,
			operator: harvest.responsavelColheita,
			totalAmount: harvest.quantidade,
			harvestDate: new Date(date),
			forecast: false, 
			pending: false,
			cropSeasons
		};
		await harvestService.update(harvestToUpdate);
	};

	const handleAddSafra = async () => {
		let newItemsSafra = [...itemsSafra];
		for (let j = 0; j < selected.length; j++) {
			let safra = "";
			let quantidade = "";
			for (let i = 0; i < allSafras.length; i++) {
				if (dataAllSafras[i].id === selected[j]) {
					quantidade = document.getElementById(
						dataAllSafras[i].id + dataAllSafras[i].nome
					).value;
					safra = dataAllSafras[i];
					newItemsSafra = {
						itemId: safra.id,
						itemName: safra.nome,
						itemQuantity: quantidade,
					};
					itemsSafra.push(newItemsSafra);
					break;
				}
			}
		}
		return;
	};

	const handleRemove = (index) => {
		const newItems = itemsSafra.filter(
			(item, itemIndex) => itemIndex !== index
		);

		setItemsSafra(newItems);
	};
	const handleRemoveAll = () => {
		setItemsSafra([]);
		handleCloseSafra();
		handleCloseColher();
		setMsgError("");
		setValidationError(false);
	};
	const handleOpenSafra = () => {
		setSelected([]);
		setOpenSafra(true); //abre o modal
	};
	const handleCloseSafra = () => {
		setSelected([]);
		setOpenSafra(false); //fecha o modal
	};

	const handleCloseAddSafra = () => {
		let Aux = 0;
		let AuxError = 0;
		for (let j = 0; j < selected.length; j++) {
			let quantidade = "";
			for (let i = 0; i < dataAllSafras.length; i++) {
				if (dataAllSafras[i].id === selected[j]) {
					quantidade = document.getElementById(
						dataAllSafras[i].id + dataAllSafras[i].nome
					).value;
					if (quantidade <= 0) {
						let getElementById = document.getElementById(
							dataAllSafras[i].id + dataAllSafras[i].nome + "controlError"
						);
						getElementById.className = styles.errControl;
						AuxError += 1;
					} else {
						let getElementById = document.getElementById(
							dataAllSafras[i].id + dataAllSafras[i].nome + "controlError"
						);
						getElementById.className = "";

						Aux += 1;
					}
				}
			}
		}
		if (Aux > 0 && AuxError <= 0) {
			handleAddSafra(); //adiciona o item no array
			setOpenSafra(false); //fecha o modal
			setErrorAddSafra(false);
		} else {
			if (selected.length === 0) setErrorAddSafra(true);
			else setErrorAddSafra(false);
		}
	};
	const dataAllSafras = allSafras.map((item, index) => {
		return {
			id: item.id,
			nome: item.nome,
			data_plantio: new Date(item.safra.dataPlantio).toLocaleDateString(
				"pt-BR"
			),
			quantidade: (
				<div id={item.id + item.nome + "controlError"}>
					<input
						id={item.id + item.nome}
						type={"number"}
						placeholder="quantidade"
					/>
				</div>
			),
			index: index,
		};
	});
	const isSelected = (name) => selected.indexOf(name) !== -1;

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = allSafras.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	const handleClick = (e, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};
	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};
	function descendingComparator(a, b, orderBy) {
		if (orderBy === "data_solicitacao") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}
		if (orderBy === "data_realizacao") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}

		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(dataFilter, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage,dataFilter]
	);
	return (
		<div>
			<Paper>
				<TableContainer>
					<Table stickyHeader>
						<EnhancedTableHead
							className={styles.tableGenerator}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClicks}
							onRequestSort={handleRequestSort}
							rowCount={dataFilter.length}
						/>
						<TableBody>
							{
								visibleRows
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										return (
											<TableRow
												hover
												tabIndex={-1}
												key={row.id}
												sx={{ height: 3 }}
											>
												{keys.map((column) => {
													//seta as colunas
													return (
														<TableCell
															id={row.id_cuture}
															// onClick={handleOpen}
															key={column}
														>
															{row[column]}
														</TableCell>
													);
												})}
												<TableCell sx={{ width: "30%" }}>
													<button
														className={styles.harvestButton}
														onClick={handleOpenColher}
														id={row.id}
													>
														Colher
													</button>
												</TableCell>
											</TableRow>
										);
									})
							}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
					component="div"
					colSpan={7}
					count={visibleRows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					labelRowsPerPage="Linhas por pagina"
					SelectProps={{
						inputProps: {
							"aria-label": "rows per page",
						},
						native: false,
					}}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					ActionsComponent={TablePaginationActions}
				/>
			</Paper>
			<Modal
				open={openColher}
				onClose={handleCloseColher}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
					}}
				>
					<Grid
						container
						rowSpacing={2}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={stylesItem}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item sx={stylesItem}>
								<p className={styles.registrationTitle}>Colher</p>
							</Item>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Item sx={stylesItem}>
								<p className={styles.modalContenteError}>{msgError}</p>
							</Item>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Box	Box sx={{
								width: "100%",
								display: "flex",
								justifyContent: "flex-start",
								flexDirection: "column",
								alignItems: "flex-start",
								px: 1,
								pr:2,
								position: "relative",
								m:0
							}}>
								<Typography variant="caption" display="block" gutterBottom sx={{top:-25 ,position:"absolute",px:1,backgroundColor:"white",visibility:(date ? "visible" : "hidden"),transition:"all 1s ease"}} >
									Data de colheita
								</Typography>
								<div
									className={
										validationError === false
											? styles.registrationInput
											: styles.registrationInputRed
									}
								>
									<InputData placeholder="Data de colheita" onDateChange={handleDateChange} value={date !== null ? new Date(date).toLocaleDateString("pt-BR") : date} classN={styles.inputDataDemanda}/>	
								</div>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Item sx={stylesItem}>
								<div className={styles.registrationTitle}>
									<a onClick={handleOpenSafra} className={styles.addBtn}>
										Adicionar safras
										<AddIcon style={styleAdd} />
									</a>
								</div>
							</Item>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Item sx={{ minHeight: "45px" }}>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "start",
										width: "100%",
									}}
								>
									{itemsSafra.map((item, index) => (
										<Grid item key={index}>
											<Item sx={stylesItem}>
												<div className={styles.itemNameCuture}>
													<button
														className={styles.itemButtonCuture}
														type="button"
														id={item.itemId}
														onClick={() => handleRemove(index)}
													>
														X
													</button>
													{item.itemName + " - " + item.itemQuantity}
												</div>
											</Item>
										</Grid>
									))}
								</Grid>
							</Item>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Item sx={stylesItem}>
								<div className={styles.cultureNameModal}>
									<p>Cultura/variedade</p> <p>{culture}</p>
								</div>
							</Item>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Item sx={stylesItem}>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 0, sm: 0, md: 0 }}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "end",
										flexDirection: {
											xs: "column-reverse",
											sm: "column-reverse",
											md: "row",
										},
										pt: 2,
									}}
								>
									<Grid item xs={12} sm={12} md={3}>
										<Item sx={stylesItem}>
											<button
												type="button"
												onClick={handleRemoveAll}
												className={styles.registrationButtonCancelar}
											>
												Cancelar
											</button>
										</Item>
									</Grid>
									<Grid item xs={12} sm={12} md={3}>
										<Item sx={stylesItem}>
											<button
												className={styles.registrationButtoncadastrar}
												type="button"
												onClick={handleCloseBtnAdd}
											>
												Cadastrar
											</button>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<Modal
				open={openSafra}
				onClose={handleCloseSafra}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 2, md: 0 }}
						sx={stylesItem}
						className={styles.registrationInput}
					>
						<Grid item xs={12} sm={12} md={12}>
							<div className={styles.titleTabelaModal}>
								<h2>
									<b>Adicionar Safras</b>
								</h2>
								<HelpButtonInput
									title={"Tabela de Safras"}
									text={"Selecione a Safra desejada e adicione uma valor"}
								/>
								{errorAddSafra ? (
									<p className={styles.errText}>
										Adicione pelo menos uma Safra
									</p>
								) : (
									""
								)}
							</div>
							<Paper sx={{ width: "100%", p: 0, m: 0 }}>
								<ThemeProvider theme={theme}>
									<TableContainer>
										<Table stickyHeader>
											<TableHead className={styles.tableGenerator}>
												<TableRow>
													<TableCell padding="checkbox">
														<Checkbox
															color="success"
															indeterminate={
																selected.length > 0 &&
																selected.length < dataAllSafras.length
															}
															checked={
																dataAllSafras.length > 0 &&
																selected.length === dataAllSafras.length
															}
															onChange={handleSelectAllClick}
															inputProps={{
																"aria-label": "select all desserts",
															}}
														/>
													</TableCell>
													{
														//cabeçalho da tabela
														columnsForActive.map((column) => (
															<TableCell key={column.id}>
																{column}
															</TableCell>
														))
													}
													{/* <TableCell> </TableCell> */}
												</TableRow>
											</TableHead>
											<TableBody>
												{
													//cria uma linha por objeto
													dataAllSafras
														.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map((row, index) => {
															const isItemSelected = isSelected(row.id);
															return (
																<TableRow
																	hover
																	role="checkbox"
																	aria-checked={isItemSelected}
																	tabIndex={-1}
																	key={index}
																>
																	<TableCell padding="checkbox">
																		<Checkbox
																			color="success"
																			checked={isItemSelected}
																			id={row.id}
																			onClick={(event) =>
																				handleClick(event, row.id)
																			}
																		/>
																	</TableCell>
																	{keysForActive.map((column) => {
																		return (
																			<TableCell
																				key={column}
																				id={row.id}
																				onClick={(event) =>
																					column === "quantidade"
																						? ""
																						: handleClick(event, row.id)
																				}
																			>
																				{row[column]}
																			</TableCell>
																		);
																	})}
														
																</TableRow>
															);
														})
												}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[
											7,
											14,
											21,
											{ label: "Todos", value: Number.MAX_VALUE },
										]}
										component="div"
										colSpan={7}
										count={dataAllSafras.length}
										rowsPerPage={rowsPerPage}
										page={page}
										labelRowsPerPage="Linhas por pagina"
										SelectProps={{
											inputProps: {
												"aria-label": "rows per page",
											},
											native: false,
										}}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
									/>
								</ThemeProvider>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Item sx={stylesItem}>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 0, sm: 0, md: 0 }}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "end",
										flexDirection: {
											xs: "column-reverse",
											sm: "column-reverse",
											md: "row",
										},
										pt: 2,
									}}
								>
									<Grid item xs={12} sm={12} md={3}>
										<Item sx={stylesItem}>
											<button
												type="button"
												onClick={handleCloseSafra}
												className={styles.registrationButtonCancelar}
											>
												Cancelar
											</button>
										</Item>
									</Grid>
									<Grid item xs={12} sm={12} md={3}>
										<Item sx={stylesItem}>
											<button
												className={styles.registrationButtoncadastrar}
												type="button"
												onClick={handleCloseAddSafra}
											>
												Cadastrar
											</button>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div>
	);
}

export default HarvestDemand;
