import React, { useEffect, useState } from "react";
import { LocationClient, SearchPlaceIndexForTextCommand } from "@aws-sdk/client-location";

//import css
/* import "./style.css"; */
import "./../../../node_modules/ol/ol.css";
import styles from "./Property.module.css";

//import dos componentes||assets
import DropDownProperty from "../../components/DropDownProperty";
import * as I from "../../components/IconsImport";
import InputMask from "../../components/InputMask";
import Search from "../../components/SearchHeader";
import TopMenu from "../../components/TopMenu";

import CircleIcon from "@mui/icons-material/Circle";
import Box from "@mui/material/Box";
import Btn from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// import * as Button from "../../components/Buttons";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Slide,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Alert, Modal, Snackbar, Stack } from "@mui/material";
import { PropertyService } from "../../service/property_service";

import Map from "ol/Map";
import View from "ol/View";
import { XYZ } from "ol/source";
import TileLayer from "ol/layer/Tile";
import { useGeographic } from "ol/proj";
import Geolocation from "ol/Geolocation";
import { LineString, Point, Polygon } from "ol/geom";
import Feature from "ol/Feature";
import {
	Circle as CircleStyle,
	Fill,
	Stroke,
	Icon,
	Style,
	Text,
} from "ol/style";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Draw } from "ol/interaction";
import { defaults as defaultControls, FullScreen } from "ol/control.js";

import propriedadeMarkerMap from "../../Assets/icons/propriedadeMarkerMap.png";
import talhaoMarkerMap from "../../Assets/icons/talhaoMarkerMap.png";
import parcelaMarkerMap from "../../Assets/icons/parcelaMarkerMap.png";
import reservatorioMarkerMap from "../../Assets/icons/reservatorioMarkerMap.png";
import sedeMarketMap from "../../Assets/icons/sedeMarketMap.png";
import corpoHidricoMarkerMap from "../../Assets/icons/CorpoHidricoMarkerMap.png";
import reservaLegalMarkerMap from "../../Assets/icons/ReservaLegalMarkerMap.png";

import hash from "object-hash";

useGeographic();

let draw = null;
let currentPlotToEditArea = null;
let currentOtherAreaToEditArea = null;
let currentSubdivisionToEditArea = null;
let currentSubdivisionToEditAreaIsPoint = false;

const geolocation = new Geolocation({
	trackingOptions: {
		enableHighAccuracy: true,
	},
});

const locationClient = new LocationClient({
	region: process.env.REACT_APP_AWS_REGION,  // Substitua pela sua região AWS
	credentials: {
		accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,     // Substitua pela sua Access Key
		secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY  // Substitua pela sua Secret Key
	}
});

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const styleIcon = { color: "#00A69A", margin: 0 };

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: "100%",
	transform: "translate(-50%, -50%)",
};

const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	width: "100%",
	m: 0,
	p: 0,
};
const styleBtn = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "end",
	justifyContent: "end",
	height: "100%",
	width: "100%",
	m: 0,
	p: 0,
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function Property() {
	const areaType = {
		CORPOSHISDRICOS: "Corpo hidríco",
		RESERVATORIO: "Reservatorio",
		SEDE: "Sede",
		RESERVALEGAL: "Reserva legal"
	};

	//Mask
	const [cpfCnpjMask, setCpfCnpjMask] = useState("999.999.999-999");

	//property
	const [openPropertyRegisterModal, setOpenPropertyRegisterModal] = useState(false);
	const [isNewProperty, setIsNewProperty] = useState(false);
	const [property, setProperty] = useState({
		nome: "",
		cpf_cnpj: "",
		area_hectares: null,
		propriedadeCoordenada: [],
		endereco: {
			bairro: "",
			cidade: "",
			uf: "",
			cep: "",
			endereco: "",
		},
		talhoes: [],
		outrasAreasPropriedades: []
	});
	const [oldProperty, setOldProperty] = useState(null);

	//Plot
	const [plot, setPlot] = useState({
		nome: "",
		area_hectares: null,
	});
	const [openPlot, setOpenPlot] = useState(false);

	//services
	const propertyService = new PropertyService();

	//messages controller
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	// const [isSatelite, setIsSatelite] = useState(true);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [addComplexArea] = React.useState(false);
	const open = Boolean(anchorEl);
	const [openDialog, setOpenDialog] = useState(false); //modal
	const [openDialogPropriedade, setOpenDialogPropriedade] = useState(false); //modal
	const [openAlert, setOpenAlert] = useState(false);
	const [removiItem] = useState({});
	const [anchorElAdd, setAnchorElAdd] = React.useState(null);
	const [map, setMap] = React.useState(null);
	const [isToHide, setIsToHide] = React.useState(true);
	const [openDrawModal, setOpenDrawModal] = React.useState(false);
	const openAdd = Boolean(anchorElAdd);
	const allCategories = {
		PARCELA: "Parcela",
		SETOR: "Setor",
		CANTEIRO: "Canteiro",
		BANCADA: "Bancada",
		BALCAO: "Balcão",
		BLOCO: "Bloco",
		GLEBA: "Gleba",
		QUADRA: "Quadra",
		LOTE: "Lote",
	};

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		handleGeocode("62700000");
		if(map)
			map.setTarget(null);
		const hasProperty = checkHasProperty();
		if(hasProperty){
			const propertyId = localStorage.getItem("propertyId");
			let currentProperty = await propertyService.getById(propertyId);
	
			if (currentProperty.propriedadeCoordenada && currentProperty.propriedadeCoordenada.length > 0) {
				const midPoint = getCenterOfPolygon(currentProperty.propriedadeCoordenada);
				const mapToEdit = await initMap(midPoint);
				currentProperty = setPropertyDrawArea(currentProperty, mapToEdit);
				for(let i = 0; i < currentProperty.talhoes.length; i++){
					let plot = currentProperty.talhoes[i];
					plot.hash = hash(plot);
					plot = setSubdivisionsDrawArea(plot , mapToEdit);
					currentProperty.talhoes[i] = plot;
					currentProperty = setPlotDrawArea(currentProperty, i , mapToEdit);
				}

				for(let i = 0; i < currentProperty.outrasAreasPropriedades.length; i++){
					currentProperty.outrasAreasPropriedades[i].nome = areaType[currentProperty.outrasAreasPropriedades[i].tipoArea];
					const otherArea = currentProperty.outrasAreasPropriedades[i];
					if(otherArea.tipoArea === "RESERVATORIO" && otherArea.outrasAreasPropriedadeCoordenada.length > 0)
						currentProperty = setOthersDrawArea(currentProperty, i , mapToEdit,  "#000", "#14FFF1", "Point", reservatorioMarkerMap);
					if(otherArea.tipoArea === "SEDE" && otherArea.outrasAreasPropriedadeCoordenada.length > 0)
						currentProperty = setOthersDrawArea(currentProperty, i , mapToEdit,  "#FFF", "#000", "Point", sedeMarketMap);
					if(otherArea.tipoArea === "CORPOSHISDRICOS" && otherArea.outrasAreasPropriedadeCoordenada.length > 0)
						currentProperty = setOthersDrawArea(currentProperty, i , mapToEdit,  "#000", "#0401AF", "LineString", corpoHidricoMarkerMap);
					if(otherArea.tipoArea === "RESERVALEGAL" && otherArea.outrasAreasPropriedadeCoordenada.length > 0)
						currentProperty = setOthersDrawArea(currentProperty, i , mapToEdit,  "#000", "#AA5D5D", "Polygon", reservaLegalMarkerMap);
				}
			} else {
				navigator.geolocation.getCurrentPosition(onSuccess, onError, {
					enableHighAccuracy: true,
					maximumAge: 10000,
				});
			}
			setProperty(currentProperty);
		} else {
			navigator.geolocation.getCurrentPosition(onSuccess, onError, {
				enableHighAccuracy: true,
				maximumAge: 10000,
			});
		}
	};

	const handleGeocode = async (cep) => {
		try {
			const params = {
				IndexName: process.env.REACT_APP_LOCATION_INDEX,
				Text: cep,  // O CEP que você quer geocodificar
				MaxResults: 1
			};
	
			const command = new SearchPlaceIndexForTextCommand(params);
			const response = await locationClient.send(command);

			console.log(response);

			if (response.Results && response.Results.length > 0) {
				const { Geometry } = response.Results[0].Place;
				const { Point } = Geometry; // Point é um array com [longitude, latitude]
				console.log({ latitude: Point[1], longitude: Point[0] });
			} else {
				console.error("Nenhuma coordenada encontrada para esse CEP.");
			}
		} catch (err) {
			console.error("Erro ao geocodificar o CEP:", err);
		}
	};
	
	const onSuccess = async (position) => {
		const { latitude, longitude } = position.coords;
		const coords = [longitude, latitude];
		initMap(coords);
	};

	const onError = async () => {
		const coords = [-38.5434, -3.71839];
		initMap(coords);
	};

	const initMap = async (coordinates) => {
		const newMap = new Map({
			controls: defaultControls().extend([new FullScreen()]),
			target: "map",
			layers: [
				new TileLayer({
					source: new XYZ({
						url: `https://maps.geo.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/maps/v0/maps/${process.env.REACT_APP_MAP_NAME}/tiles/{z}/{x}/{y}?key=${process.env.REACT_APP_MAP_API_KEY}`,
						wrapX: false,
					}),
				}),
			],
			view: new View({
				center: coordinates,
				zoom: 16,
			}),
		});

		geolocation.setTracking(true);

		geolocation.on("error", function (error) {
			console.error(error);
		});

		const positionFeature = new Feature();
		positionFeature.setStyle(
			new Style({
				image: new CircleStyle({
					radius: 6,
					fill: new Fill({
						color: "#3399CC",
					}),
					stroke: new Stroke({
						color: "#fff",
						width: 2,
					}),
				}),
			})
		);

		geolocation.on("change:position", function () {
			const currentCoordinates = geolocation.getPosition();
			positionFeature.setGeometry(
				currentCoordinates ? new Point(currentCoordinates) : null
			);
		});

		new VectorLayer({
			map: newMap,
			source: new VectorSource({
				features: [positionFeature],
			}),
			updateWhileAnimating: true,
			updateWhileInteracting: true,
		});

		setMap(newMap);
		return newMap;
	};

	const checkHasProperty = () => {
		const propertyId = localStorage.getItem("propertyId");
		if(!propertyId)
			setOpenPropertyRegisterModal(true);
		setIsNewProperty(propertyId === null);

		return propertyId !== null;
	};

	const setPropertyName = (name) => {
		setProperty({...property, nome: name});
	};

	const setPropertyCpfCnpj = (cpfCnpj) => {
		setProperty({...property, cpf_cnpj: cpfCnpj});
	};

	const setPropertyArea = (area) => {
		setProperty({...property, area_hectares: Number(area)});
	};

	const setPropertyNeighborhood = (neighborhood) => {
		const address = property.endereco;
		setProperty({...property, endereco: {...address, bairro: neighborhood}});
	};

	const setPropertyCity = (city) => {
		const address = property.endereco;
		setProperty({...property, endereco: {...address, cidade: city}});
	};

	const setPropertyCep = (cep) => {
		const address = property.endereco;
		setProperty({...property, endereco: {...address, cep: cep}});
	};

	const setPropertyExtraAddress = (address) => {
		const propAddress = property.endereco;
		setProperty({...property, endereco: {...propAddress, endereco: address}});
	};

	const setPropertyUf = (uf) => {
		const address = {...property.endereco, uf: uf};
		setProperty({...property, endereco: address});
	};

	const setPlotName = (name) => {
		setPlot({...plot, nome: name});
	};

	const setPlotArea = (area) => {
		setPlot({...plot, area_hectares: Number(area)});
	};

	function handleChangeCEP(cepText) {
		if (cepText.length > 7) {
			fetch(`https://viacep.com.br/ws/${cepText}/json/`, {
				method: "GET",
			})
				.then((response) => response.json())
				.then((data) => {
					if (!("erro" in data)) {
						const address = {...property.endereco, 
							uf: data.uf,
							cidade: data.localidade,
							bairro: data.bairro,
							endereco: data.logradouro
						};
						setProperty({...property, endereco: address});
					}
				})
				.catch(() => {
					setPropertyUf("0");
				});
		}
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClickAdd = (event) => {
		setAnchorElAdd(event.currentTarget);
	};

	const handleCloseAdd = () => {
		setAnchorElAdd(null);
	};

	const handleClosePropertyRegisterModal = () => {
		setProperty(oldProperty);
		setOpenPropertyRegisterModal(false); //fecha o modal
	};

	const handleClickAlert = async () => {
		await savePropertyData();
		setOpenAlert(true);
	};

	const openPropertyToEdit = async () => {
		setOldProperty(property);
		setOpenPropertyRegisterModal(true);
		handleClose();
	};

	const openPropertyToCreate = async () => {
		setOldProperty(property);
		setIsNewProperty(true);
		setProperty({
			nome: "",
			cpf_cnpj: "",
			area_hectares: null,
			propriedadeCoordenada: [],
			endereco: {
				bairro: "",
				cidade: "",
				uf: "",
				cep: "",
				endereco: "",
			},
			talhoes: [],
			outrasAreasPropriedades: []
		});
		setOpenPropertyRegisterModal(true);
	};

	const savePropertyData = async () => {
		const isPropertyDataValid = validatePropertyData();
		if (isPropertyDataValid) {
			const user = JSON.parse(localStorage.getItem("user"));
			const businessId = user.businessId;

			const newProperty = {
				name: property.nome,
				cpf_cnpj: property.cpf_cnpj,
				businessId: businessId,
				acre_area: property.area_hectares,
				coordinates: [],
				neighborhood: property.endereco.bairro,
				cep: property.endereco.cep,
				city: property.endereco.cidade,
				federativeUnit: property.endereco.uf,
				address: property.endereco.endereco,
			};

			if (isNewProperty) {
				const savedProperty = await propertyService.save(newProperty);
				localStorage.setItem("propertyId", savedProperty.id);
				setOldProperty(null);
			} else {
				newProperty.coordinates = property.propriedadeCoordenada.map((coordinate) => {
					return {
						latitude: coordinate.latitude,
						longitude: coordinate.longitude
					};
				});
				newProperty.id = property.id;
				await propertyService.update(newProperty);
				window.location.reload();
			}
			initData();
			setOpenPropertyRegisterModal(false);
			setTypeMessage("success");
			setMessage("cadastro salvo/alterado!");
			window.location.reload();
		}
	};

	const validatePropertyData = () => {
		const isCpfCnpjValid = validateCpfCnpj(property.cpf_cnpj);
		const isPropertyNameValid = validateName(property.nome);
		const isPropertyCityValid = validateCity(property.endereco.cidade);
		const isPropertyUfValid = validateUf(property.endereco.uf);
		const isPropertyNeighborhoodValid = validateNeighborhood(property.endereco.bairro);
		const isPropertyAddressValid = validateAddress(property.endereco.endereco);
		const isPropertyAreaValid = validateArea(property.area_hectares);
		return isCpfCnpjValid && isPropertyNameValid && isPropertyCityValid
			&& isPropertyUfValid && isPropertyNeighborhoodValid && isPropertyAddressValid
			&& isPropertyAreaValid;
	};

	const validateCpfCnpj = (CpfCnpj) => {
		if(CpfCnpj && (CpfCnpj.length === 11 || CpfCnpj.length === 14)){
			return true;
		}else {
			setTypeMessage("error");
			setMessage("CPF/CNPJ invalido!!!");
			return false;
		}
	};

	const validateName = (name) => {
		const isNotEmpty = validateEmptyText(name);
		if(!isNotEmpty){
			setTypeMessage("error");
			setMessage("Nome invalido!!!");
		}
		return isNotEmpty;
	};

	const validateUf = (uf) => {
		if(uf && uf !== "0"){
			return true;
		}else {
			setTypeMessage("error");
			setMessage("Estado é obrigatório!!!");
			return false;
		}
	};

	const validateCity = (city) => {
		const isNotEmpty = validateEmptyText(city);
		if(!isNotEmpty){
			setTypeMessage("error");
			setMessage("Cidade é obrigatória!!!");
		}
		return isNotEmpty;
	};

	const validateNeighborhood = (neighborhood) => {
		const isNotEmpty = validateEmptyText(neighborhood);
		if(!isNotEmpty){
			setTypeMessage("error");
			setMessage("Bairro é obrigatório!!!");
		}
		return isNotEmpty;
	};

	const validateAddress = (addres) => {
		const isNotEmpty = validateEmptyText(addres);
		if(!isNotEmpty){
			setTypeMessage("error");
			setMessage("Endereço é obrigatório!!!");
		}
		return isNotEmpty;
	};

	const validateArea = (area) => {
		if(area !== null && area !== undefined && Number(area) >= 0)
			return true;

		setTypeMessage("error");
		setMessage("Área é obrigatório!!!");
		return false;
	};

	const validateEmptyText = (text) => (text && text.length > 0);

	const handleCloseAlert = (event, reason) => {
		if (reason === "clickaway") return;

		setOpenAlert(false);
	};

	const handleCloseData = () => {
		setOpenPlot(false);
	};

	const savePlotData = async () => {
		const isPlotDataValid = validatePlotData(plot);
		if (isPlotDataValid) {
			const newPlot = {
				name: plot.nome,
				active: true,
				acre_area: plot.area_hectares,
				coordinates: [],
				propertyId: property.id,
			};

			const savedPlot = await propertyService.savePlot(newPlot);

			setOpenPlot(false);
			setTypeMessage("success");
			setMessage("cadastro salvo/alterado!");
			editPlotArea(savedPlot);
		}
	};

	const updatePlotData = async (newPlot) => {
		const isPlotDataValid = validatePlotData(newPlot);
		if (isPlotDataValid) {

			const plotToUpdate = {
				name: newPlot.nome,
				active: newPlot.ativo,
				acre_area: Number(newPlot.area_hectares),
				coordinates: newPlot.talhaoCoordenada.map((coordinate) => {
					return {
						latitude: coordinate.latitude,
						longitude: coordinate.longitude
					};
				}),
				propertyId: property.id,
				id: newPlot.id
			};
			
			await propertyService.updatePlot(plotToUpdate);

			setOpenPlot(false);
			setTypeMessage("success");
			setMessage("cadastro salvo/alterado!");

			setPlot({
				nome: "",
				area_hectares: null,
			});
			initData();
		}
	};

	const validatePlotData = (plot) => {
		const isPlotNameValid = validateName(plot.nome);
		const isPlotAreaValid = validateArea(plot.area_hectares);
		return isPlotNameValid && isPlotAreaValid;
	};

	const deletePlot = async (plot) => {
		handleClose();
		handleCloseAdd();
		if(plot.layer)
			map.removeLayer(plot.layer);
		await propertyService.deletePlot(plot.id);
		initData();
	};

	const deleteSubdivision = async (subdivision) => {
		handleClose();
		handleCloseAdd();
		if(subdivision.layer)
			map.removeLayer(subdivision.layer);
		await propertyService.deleteSubdivision(subdivision.id);
		
		initData();
	};

	const openPlotRegister = () => {
		handleClose();
		handleCloseAdd();
		setOpenPlot(true);
	};

	const saveSubdivisionData = async (
		subdivision,
		isPoint
	) => {
		const isSubdivisionDataValid = validateSubdivisionData(subdivision);
		if (isSubdivisionDataValid) {

			if(!subdivision.id) {
				const saveSubdivision = await propertyService.saveSubdivision(subdivision);
				editSubdivisionArea(saveSubdivision, isPoint);
			} else {
				if(subdivision.subdivisaoCoordenada){
					subdivision.coordinates = subdivision.subdivisaoCoordenada.map((coordinate) => {
						return {
							latitude: coordinate.latitude,
							longitude: coordinate.longitude
						};
					});
				}

				await propertyService.updateSubdivision(subdivision);
			
				initData();
			}
			setTypeMessage("success");
			setMessage("cadastro salvo/alterado!");
		}
	};

	const validateSubdivisionData = (subdivision) => {
		const isSubdivisionNameValid = validateName(subdivision.complement);
		const isSubdivisionAreaValid = validateArea(subdivision.acre_area);
		return isSubdivisionNameValid && isSubdivisionAreaValid;
	};

	const addWaterBody = async () => {
		handleClose();
		handleCloseAdd();
		const waterBody = {
			areaType: "CORPOSHISDRICOS",
			propertyId: property.id,
			coordinates: []
		};
		const savedOtherArea = await propertyService.saveOtherArea(waterBody);
		editOthersArea(savedOtherArea);
	};

	const addReservoir = async () => {
		handleClose();
		handleCloseAdd();
		const reservoir = {
			areaType: "RESERVATORIO",
			propertyId: property.id,
			coordinates: []
		};

		const savedOtherArea = await propertyService.saveOtherArea(reservoir);
		editOthersArea(savedOtherArea);
	};

	const addHeadQuarters = async () => {
		handleClose();
		handleCloseAdd();
		const headQuarters = {
			areaType: "SEDE",
			propertyId: property.id,
			coordinates: []
		};

		const savedOtherArea = await propertyService.saveOtherArea(headQuarters);
		editOthersArea(savedOtherArea);
	};

	const addLegalReserve = async () => {
		handleClose();
		handleCloseAdd();
		const legalReserve = {
			areaType: "RESERVALEGAL",
			propertyId: property.id,
			coordinates: []
		};

		const savedOtherArea = await propertyService.saveOtherArea(legalReserve);
		editOthersArea(savedOtherArea);
	};

	const deleteOtherArea = async (otherArea) => {
		handleClose();
		handleCloseAdd();
		if(otherArea.layer)
			map.removeLayer(otherArea.layer);
		await propertyService.deleteOtherArea(otherArea.id);
		
		initData();
	};

	const updateOtherArea = async (otherArea) => {
		const dataToSave = {
			id: otherArea.id,
			areaType: otherArea.tipoArea,
			propertyId: otherArea.propriedadeId,
			coordinates: otherArea.outrasAreasPropriedadeCoordenada.map((coordinate) => {
				return {
					latitude: coordinate.latitude,
					longitude: coordinate.longitude
				};
			})
		};
		await propertyService.updateOtherArea(dataToSave);
		
		initData();
	};

	function formatCpfCnpj() {
		let cpfCnpjNumbers =
			property.cpf_cnpj !== null && property.cpf_cnpj !== undefined ? property.cpf_cnpj.split("") : [];
		if (cpfCnpjNumbers.length === 11) {
			return (
				cpfCnpjNumbers[0] +
				cpfCnpjNumbers[1] +
				cpfCnpjNumbers[2] +
				"." +
				cpfCnpjNumbers[3] +
				cpfCnpjNumbers[4] +
				cpfCnpjNumbers[5] +
				"." +
				cpfCnpjNumbers[6] +
				cpfCnpjNumbers[7] +
				cpfCnpjNumbers[8] +
				"-" +
				cpfCnpjNumbers[9] +
				cpfCnpjNumbers[10]
			);
		} else if (cpfCnpjNumbers.length === 14) {
			return (
				cpfCnpjNumbers[0] +
				cpfCnpjNumbers[1] +
				"." +
				cpfCnpjNumbers[2] +
				cpfCnpjNumbers[3] +
				cpfCnpjNumbers[4] +
				"." +
				cpfCnpjNumbers[5] +
				cpfCnpjNumbers[6] +
				cpfCnpjNumbers[7] +
				"/" +
				cpfCnpjNumbers[8] +
				cpfCnpjNumbers[9] +
				cpfCnpjNumbers[10] +
				cpfCnpjNumbers[11] +
				"-" +
				cpfCnpjNumbers[12] +
				cpfCnpjNumbers[13]
			);
		} else {
			return "CPF/CNPJ Inválido";
		}
	}

	const handleOpenDialogDelet = () => {
		handleClose();
		setOpenDialogPropriedade(true);
	};

	const handleCloseDialogDelet = () => {
		setOpenDialogPropriedade(false); //fecha o modal
	};

	const deleteProperty = async () => {
		setOpenDialogPropriedade(false);
		const user = JSON.parse(localStorage.getItem("user"));
		const properties = await propertyService.getByBusiness(user.businessId);
		if(properties.length > 1){
			const propertyId = localStorage.getItem("propertyId");
			await propertyService.delete(propertyId);
			const selectedProperty = properties.find((property) => property.id !== propertyId);
			localStorage.setItem("propertyId", selectedProperty.id);
			window.location.reload(true);
		} else {
			alert("Não é possível excluir quando há somente uma propriedade");
		}
	};

	const handleCloseDialog = () => {
		setOpenDialog(false); //fecha o modal
	};

	const editPropertyArea = () => {
		if(property.layer)
			map.removeLayer(property.layer);
		startPropertyEditInteraction();
		handleClose();
	};

	const startPropertyEditInteraction = () => {
		const style = new Style({
			fill: new Fill({
				color: "#FFFFFF26",
			}),
			stroke: new Stroke({
				color: "#FFFFFF",
				width: 4,
			}),
			image: new CircleStyle({
				radius: 10,
				fill: new Fill({
					color: "#FFFFFF",
				}),
			}),
		});

		draw = new Draw({
			type: "Polygon",
		});
		
		draw.on("drawend", async function (e) {
			setIsToHide(true);
			e.feature.setStyle(style);

			const coordinates = [];
			for (
				let coordIndex = 0;
				coordIndex < draw.sketchLineCoords_.length;
				coordIndex++
			) {
				const coord = draw.sketchLineCoords_[coordIndex];
				coordinates.push({
					longitude: Number(coord[0]),
					latitude: Number(coord[1]),
				});
			}

			property.propriedadeCoordenada = coordinates;

			await savePropertyData();

			map.removeInteraction(draw);
			setPropertyDrawArea(property, map);
		});
		setIsToHide(false);
		setOpenDrawModal(true);

		map.addInteraction(draw);
	};

	const setPropertyDrawArea = (propertyToEdit, mapToEdit) => {
		const midPoint = getCenterOfPolygon(propertyToEdit.propriedadeCoordenada);

		const polygonFeatureT = createPolygonFeatureToMap(
			propertyToEdit.propriedadeCoordenada,
			"#e01a1a"
		);

		const iconFeature = new Feature({
			geometry: new Point(midPoint),
			name: propertyToEdit.nome,
		});

		const iconStyle = new Style({
			image: new Icon({
				anchor: [0.5, 550],
				anchorXUnits: "fraction",
				anchorYUnits: "pixels",
				scale: 0.06,
				src: propriedadeMarkerMap,
			}),
			text: new Text({
				text: propertyToEdit.nome,
				fill: new Fill({
					color: "white",
				}),

				backgroundFill: new Fill({
					color: "#e01a1a",
				}),
				textAlign: "center",
				font: "bold 15px sans-serif",
				padding: [5, 5, 5, 5],
				offsetY: -50,
			}),
		});

		iconFeature.setStyle(iconStyle);

		const layer = new VectorLayer({
			source: new VectorSource({
				features: [polygonFeatureT, iconFeature],
			}),
			updateWhileAnimating: true,
			updateWhileInteracting: true,
		});

		mapToEdit.addLayer(layer);
		setMap(mapToEdit);
		setProperty({...propertyToEdit, layer: layer});
		return {...propertyToEdit, layer: layer};
	};

	const editPlotArea = (plot) => {
		if(plot.layer)
			map.removeLayer(plot.layer);
		currentPlotToEditArea = plot;
		startPlotEditInteraction();
		handleClose();
	};

	const startPlotEditInteraction = () => {
		const style = new Style({
			fill: new Fill({
				color: "#FFFFFF26",
			}),
			stroke: new Stroke({
				color: "#FFFFFF",
				width: 4,
			}),
			image: new CircleStyle({
				radius: 10,
				fill: new Fill({
					color: "#FFFFFF",
				}),
			}),
		});

		draw = new Draw({
			type: "Polygon",
		});
		
		draw.on("drawend", async function (e) {
			setIsToHide(true);
			e.feature.setStyle(style);

			const coordinates = [];
			for (
				let coordIndex = 0;
				coordIndex < draw.sketchLineCoords_.length;
				coordIndex++
			) {
				const coord = draw.sketchLineCoords_[coordIndex];
				coordinates.push({
					longitude: Number(coord[0]),
					latitude: Number(coord[1]),
				});
			}

			currentPlotToEditArea.talhaoCoordenada = coordinates;

			await updatePlotData(currentPlotToEditArea);
			const plotId = property.talhoes.findIndex((plot) => {
				return plot.id === currentPlotToEditArea.id;
			});

			currentPlotToEditArea = null;

			map.removeInteraction(draw);
			if(property.talhoes[plotId])
				setPlotDrawArea(property, plotId, map);
		});

		map.addInteraction(draw);
		setIsToHide(false);
		setOpenDrawModal(true);
	};

	const setPlotDrawArea = (propertyToEdit, plotId, mapToEdit) => {
		const midPoint = getCenterOfPolygon(propertyToEdit.talhoes[plotId].talhaoCoordenada);

		const polygonFeatureT = createPolygonFeatureToMap(
			propertyToEdit.talhoes[plotId].talhaoCoordenada,
			"#49A09A"
		);

		const iconFeature = new Feature({
			geometry: new Point(midPoint),
			name: `Talhão ${propertyToEdit.talhoes[plotId].nome}`,
		});

		const iconStyle = new Style({
			image: new Icon({
				anchor: [0.5, 550],
				anchorXUnits: "fraction",
				anchorYUnits: "pixels",
				scale: 0.06,
				src: talhaoMarkerMap,
			}),
			text: new Text({
				text: `Talhão ${propertyToEdit.talhoes[plotId].nome}`,
				fill: new Fill({
					color: "black",
				}),

				backgroundFill: new Fill({
					color: "#49A09A",
				}),
				textAlign: "center",
				font: "bold 15px sans-serif",
				padding: [5, 5, 5, 5],
				offsetY: -50,
			}),
		});

		iconFeature.setStyle(iconStyle);

		const layer = new VectorLayer({
			source: new VectorSource({
				features: [polygonFeatureT, iconFeature],
			}),
			updateWhileAnimating: true,
			updateWhileInteracting: true,
		});

		mapToEdit.addLayer(layer);
		setMap(mapToEdit);
		propertyToEdit.talhoes[plotId].layer = layer;
		setProperty({...propertyToEdit});
		return {...propertyToEdit};
	};

	const editSubdivisionArea = (subdivision, isPoint) => {
		if(subdivision.layer)
			map.removeLayer(subdivision.layer);
		currentSubdivisionToEditArea = subdivision;
		currentSubdivisionToEditAreaIsPoint = isPoint;
		startSubdivisionEditInteraction();
		handleClose();
	};

	const startSubdivisionEditInteraction = () => {
		const style = new Style({
			fill: new Fill({
				color: "#FFFFFF26",
			}),
			stroke: new Stroke({
				color: "#FFFFFF",
				width: 4,
			}),
			image: new CircleStyle({
				radius: 10,
				fill: new Fill({
					color: "#FFFFFF",
				}),
			}),
		});

		draw = new Draw({
			type: currentSubdivisionToEditAreaIsPoint ? "Point" : "Polygon",
		});
		
		draw.on("drawend", async function (e) {
			setIsToHide(true);
			e.feature.setStyle(style);

			const coordinates = [];

			if (currentSubdivisionToEditAreaIsPoint) {
				const coord = draw.sketchCoords_;
				coordinates.push({
					longitude: Number(coord[0]),
					latitude: Number(coord[1]),
				});
			} else {
				for (
					let coordIndex = 0;
					coordIndex < draw.sketchLineCoords_.length;
					coordIndex++
				) {
					const coord = draw.sketchLineCoords_[coordIndex];
					coordinates.push({
						longitude: Number(coord[0]),
						latitude: Number(coord[1]),
					});
				}
			}

			currentSubdivisionToEditArea.subdivisaoCoordenada = coordinates;

			const subdivisionToSave = {
				id: currentSubdivisionToEditArea.id,
				complement: currentSubdivisionToEditArea.complemento,
				active: currentSubdivisionToEditArea.ativo,
				acre_area: Number(currentSubdivisionToEditArea.area_hectares),
				subdivisionCategory: currentSubdivisionToEditArea.categoriaSubdivisao,
				subdivisaoCoordenada: coordinates,
				plotId: currentSubdivisionToEditArea.talhaoId,
				parentSubdivision: currentSubdivisionToEditArea.subdivisao_pai
			};

			await saveSubdivisionData(subdivisionToSave, currentSubdivisionToEditAreaIsPoint);

			map.removeInteraction(draw);
			setSubdivisionDrawArea(property, currentSubdivisionToEditArea, map);
		});

		setIsToHide(false);
		setOpenDrawModal(true);
		map.addInteraction(draw);
	};

	const setSubdivisionDrawArea = (propertyToEdit, subdivision, mapToEdit) => {
		const midPoint = currentSubdivisionToEditAreaIsPoint ?
			[subdivision.subdivisaoCoordenada[0].longitude, subdivision.subdivisaoCoordenada[0].latitude]
			: getCenterOfPolygon(subdivision.subdivisaoCoordenada);

		const featureT = currentSubdivisionToEditAreaIsPoint ?
			createPointFeatureToMap(midPoint, "#ebef62") 
			: createPolygonFeatureToMap(
				subdivision.subdivisaoCoordenada,
				"#ebef62"
			);

		const iconFeature = new Feature({
			geometry: new Point(midPoint),
			name: `${allCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento}`,
		});

		const iconStyle = new Style({
			image: new Icon({
				anchor: [0.5, 550],
				anchorXUnits: "fraction",
				anchorYUnits: "pixels",
				scale: 0.06,
				src: parcelaMarkerMap,
			}),
			text: new Text({
				text: `${allCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento}`,
				fill: new Fill({
					color: "#000",
				}),

				backgroundFill: new Fill({
					color: "#ebef62",
				}),
				textAlign: "center",
				font: "bold 15px sans-serif",
				padding: [5, 5, 5, 5],
				offsetY: -50,
			}),
		});

		iconFeature.setStyle(iconStyle);

		const layer = new VectorLayer({
			source: new VectorSource({
				features: [featureT, iconFeature],
			}),
			updateWhileAnimating: true,
			updateWhileInteracting: true,
		});

		mapToEdit.addLayer(layer);
		setMap(mapToEdit);
		subdivision.layer = layer;
		
		const plotIndex = propertyToEdit.talhoes.findIndex((plot) => plot.id === subdivision.talhaoId);

		propertyToEdit.talhoes[plotIndex].subdivisoes = updateSubdivisionInList(propertyToEdit.talhoes[plotIndex].subdivision, subdivision);

		setProperty({...propertyToEdit});

		return {...propertyToEdit};
	};

	const updateSubdivisionInList = (subdivisions, subdivision) => {
		if(!subdivisions || subdivisions.length === 0)
			return [];

		const subIndex = subdivisions.findIndex((subdiv) => subdiv.id === subdivision.is);
		if(subIndex !== -1) {
			subdivisions[subIndex] = subdivision;
			return subdivisions;
		}

		const newSubdivisions = [];
		subdivisions.forEach(subdiv => {
			subdiv.subdivisoes = updateSubdivisionInList(subdiv.subdivisoes, subdivision);
			newSubdivisions.push(subdiv);
		});

		return newSubdivisions;
	};

	const setSubdivisionsDrawArea = (plot, mapToEdit) => {
		plot.subdivisoes = drawSubdivisions(plot.subdivisoes, mapToEdit);
		return plot;
	};

	const drawSubdivisions = (subdivisions, mapToEdit) => {
		if(!subdivisions || subdivisions.length === 0)
			return [];

		for(let i = 0; i < subdivisions.length; i++){
			let subdivision = subdivisions[i];
			if(subdivision.subdivisaoCoordenada && subdivision.subdivisaoCoordenada.length > 0)
				subdivision = drawUniqueSubdivisionArea(subdivision, mapToEdit);
			subdivision.subdivisoes = drawSubdivisions(subdivision.subdivisoes, mapToEdit);
			subdivisions[i] = subdivision;
		}
		return subdivisions;
	};

	const drawUniqueSubdivisionArea = (subdivision, mapToEdit) => {
		const isPoint = subdivision.subdivisaoCoordenada.length === 1;
		const midPoint = isPoint ?
			[subdivision.subdivisaoCoordenada[0].longitude, subdivision.subdivisaoCoordenada[0].latitude]
			: getCenterOfPolygon(subdivision.subdivisaoCoordenada);

		const featureT = isPoint ?
			createPointFeatureToMap(midPoint, "#ebef62") 
			: createPolygonFeatureToMap(
				subdivision.subdivisaoCoordenada,
				"#ebef62"
			);

		const iconFeature = new Feature({
			geometry: new Point(midPoint),
			name: `${allCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento}`,
		});

		const iconStyle = new Style({
			image: new Icon({
				anchor: [0.5, 550],
				anchorXUnits: "fraction",
				anchorYUnits: "pixels",
				scale: 0.06,
				src: parcelaMarkerMap,
			}),
			text: new Text({
				text: `${allCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento}`,
				fill: new Fill({
					color: "#000",
				}),

				backgroundFill: new Fill({
					color: "#ebef62",
				}),
				textAlign: "center",
				font: "bold 15px sans-serif",
				padding: [5, 5, 5, 5],
				offsetY: -50,
			}),
		});

		iconFeature.setStyle(iconStyle);

		const layer = new VectorLayer({
			source: new VectorSource({
				features: [featureT, iconFeature],
			}),
			updateWhileAnimating: true,
			updateWhileInteracting: true,
		});

		mapToEdit.addLayer(layer);
		setMap(mapToEdit);
		subdivision.layer = layer;

		return subdivision;
	};

	const editOthersArea = (otherArea) => {
		if(otherArea.layer)
			map.removeLayer(otherArea.layer);
		currentOtherAreaToEditArea = otherArea;
		startOthersEditInteraction();
		handleClose();
	};

	const startOthersEditInteraction = () => {
		const style = new Style({
			fill: new Fill({
				color: "#FFFFFF26",
			}),
			stroke: new Stroke({
				color: "#FFFFFF",
				width: 4,
			}),
			image: new CircleStyle({
				radius: 10,
				fill: new Fill({
					color: "#FFFFFF",
				}),
			}),
		});

		let type = "Point";
		if(currentOtherAreaToEditArea.tipoArea === "CORPOSHISDRICOS")
			type = "LineString";
		if(currentOtherAreaToEditArea.tipoArea === "RESERVALEGAL")
			type = "Polygon";

		draw = new Draw({
			type: type,
		});
		
		draw.on("drawend", async function (e) {
			setIsToHide(true);
			e.feature.setStyle(style);

			let type = "Point";
			if(currentOtherAreaToEditArea.tipoArea === "CORPOSHISDRICOS")
				type = "LineString";
			if(currentOtherAreaToEditArea.tipoArea === "RESERVALEGAL")
				type = "Polygon";

			const coordinates = [];
			if (type === "Point") {
				const coord = draw.sketchCoords_;
				coordinates.push({
					longitude: Number(coord[0]),
					latitude: Number(coord[1]),
				});
			} else if (type === "Polygon") {
				for (
					let coordIndex = 0;
					coordIndex < draw.sketchLineCoords_.length;
					coordIndex++
				) {
					const coord = draw.sketchLineCoords_[coordIndex];
					coordinates.push({
						longitude: Number(coord[0]),
						latitude: Number(coord[1]),
					});
				}
			} else {
				for (
					let coordIndex = 0;
					coordIndex < draw.sketchCoords_.length;
					coordIndex++
				) {
					const coord = draw.sketchCoords_[coordIndex];
					coordinates.push({
						longitude: Number(coord[0]),
						latitude: Number(coord[1]),
					});
				}
			}

			currentOtherAreaToEditArea.outrasAreasPropriedadeCoordenada = coordinates;

			await updateOtherArea(currentOtherAreaToEditArea);

			map.removeInteraction(draw);

			const otherAreaIndex = property.outrasAreasPropriedades.findIndex((otherArea) => otherArea.id === currentOtherAreaToEditArea.id);

			if(currentOtherAreaToEditArea.tipoArea === "RESERVATORIO")
				await setOthersDrawArea(property, otherAreaIndex, map,  "#000", "#14FFF1", type, reservatorioMarkerMap);
			if(currentOtherAreaToEditArea.tipoArea === "SEDE")
				await setOthersDrawArea(property, otherAreaIndex, map,  "#FFF", "#000", type, sedeMarketMap);
			if(currentOtherAreaToEditArea.tipoArea === "CORPOSHISDRICOS")
				await setOthersDrawArea(property, otherAreaIndex, map,  "#000", "#0401AF", type, corpoHidricoMarkerMap);
			if(currentOtherAreaToEditArea.tipoArea === "RESERVALEGAL")
				await setOthersDrawArea(property, otherAreaIndex, map,  "#000", "#AA5D5D", type, reservaLegalMarkerMap);

			currentOtherAreaToEditArea = null;
		});

		map.addInteraction(draw);

		setIsToHide(false);
		setOpenDrawModal(true);
	};

	const setOthersDrawArea = (propertyToEdit, otherAreaIndex, mapToEdit, textColor, backgroundColor, drawType, marker) => {
		const midPoint = drawType === "Point" ? 
			[propertyToEdit.outrasAreasPropriedades[otherAreaIndex].outrasAreasPropriedadeCoordenada[0].longitude, propertyToEdit.outrasAreasPropriedades[otherAreaIndex].outrasAreasPropriedadeCoordenada[0].latitude]
			: getCenterOfPolygon(propertyToEdit.outrasAreasPropriedades[otherAreaIndex].outrasAreasPropriedadeCoordenada);

		let featureT = null;

		if(drawType === "Point") {
			featureT = createPointFeatureToMap(midPoint, backgroundColor);
		} else if(drawType === "LineString") {
			featureT = createLineStringFeatureToMap(
				propertyToEdit.outrasAreasPropriedades[otherAreaIndex].outrasAreasPropriedadeCoordenada,
				backgroundColor
			);
		} else {
			featureT = createPolygonFeatureToMap(
				propertyToEdit.outrasAreasPropriedades[otherAreaIndex].outrasAreasPropriedadeCoordenada,
				backgroundColor
			);
		}

		const iconFeature = new Feature({
			geometry: new Point(midPoint),
			name: propertyToEdit.outrasAreasPropriedades[otherAreaIndex].nome,
		});

		const iconStyle = new Style({
			image: new Icon({
				anchor: [0.5, 550],
				anchorXUnits: "fraction",
				anchorYUnits: "pixels",
				scale: 0.06,
				src: marker,
			}),
			text: new Text({
				text: propertyToEdit.outrasAreasPropriedades[otherAreaIndex].nome,
				fill: new Fill({
					color: textColor,
				}),

				backgroundFill: new Fill({
					color: backgroundColor,
				}),
				textAlign: "center",
				font: "bold 15px sans-serif",
				padding: [5, 5, 5, 5],
				offsetY: -50,
			}),
		});

		iconFeature.setStyle(iconStyle);

		const layer = new VectorLayer({
			source: new VectorSource({
				features: [featureT, iconFeature],
			}),
			updateWhileAnimating: true,
			updateWhileInteracting: true,
		});

		mapToEdit.addLayer(layer);
		setMap(mapToEdit);
		propertyToEdit.outrasAreasPropriedades[otherAreaIndex].layer = layer;
		setProperty({...propertyToEdit});
		return {...propertyToEdit};
	};

	const getCenterOfPolygon = (coordinates) => {
		let lat = 0;
		let lon = 0;
		for (var i = 0; i < coordinates.length; i++) {
			const vertex = coordinates[i];
			lat += Number(vertex.latitude);
			lon += Number(vertex.longitude);
		}
		lat = lat / coordinates.length;
		lon = lon / coordinates.length;
		return [lon, lat];
	};

	const createPolygonFeatureToMap = (coordinates, color) => {
		const points = [];
		for (var i = 0; i < coordinates.length; i++)
			points.push([coordinates[i].longitude, coordinates[i].latitude]);

		const polygonFeatureT = new Feature(new Polygon([points]));

		polygonFeatureT.setStyle(
			new Style({
				fill: new Fill({
					color: `${color}26`,
				}),
				stroke: new Stroke({
					color: color,
					width: 4,
				}),
				image: new CircleStyle({
					radius: 10,
					fill: new Fill({
						color: color,
					}),
				}),
			})
		);
		return polygonFeatureT;
	};

	const createLineStringFeatureToMap = (coordinates, color) => {
		const points = [];
		for (var i = 0; i < coordinates.length; i++)
			points.push([coordinates[i].longitude, coordinates[i].latitude]);

		const lineStringFeatureT = new Feature(new LineString(points));

		lineStringFeatureT.setStyle(
			new Style({
				fill: new Fill({
					color: `${color}26`,
				}),
				stroke: new Stroke({
					color: color,
					width: 4,
				}),
				image: new CircleStyle({
					radius: 10,
					fill: new Fill({
						color: color,
					}),
				}),
			})
		);
		
		return lineStringFeatureT;
	};

	const createPointFeatureToMap = (coordinate, color) => {
		const pointFeatureT = new Feature(new Point(coordinate));

		pointFeatureT.setStyle(
			new Style({
				fill: new Fill({
					color: `${color}26`,
				}),
				stroke: new Stroke({
					color: color,
					width: 4,
				}),
				image: new CircleStyle({
					radius: 10,
					fill: new Fill({
						color: color,
					}),
				}),
			})
		);
		return pointFeatureT;
	};

	return (
		<div>
			<TopMenu open={["register", "property"]} help="property" />

			{/* conteudo da pagina*/}
			<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								{/* header da pagina*/}
								<Search
									title="Propriedade"
									textButton="Nova propriedade"
									icon={<I.IconAdd />}
									funcClick={openPropertyToCreate}
								/>
							</Item>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={7}
							sx={{
								display: "flex",
								alignItems: "stretch",
							}}
						>
							<Item
								sx={{
									width: "100%",
								}}
							>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
								>
									<Grid item xs={12} sm={12} md={12}>
										<Item sx={{ position: "relativo" }}>
											<h2 style={{color: "red"}} hidden={isToHide}>Desenhe a área no mapa</h2>
											<div id="map" className={styles.map}>
												<div id="marker" title="Marker"></div>
												{/* <button className={styles.mapBtn}>Satelite</button> */}
											</div>
											{/* <Grid 
												rowSpacing={1}
												container 
												item 
												xs={12} 
												sm={12} 
												md={12}
												sx={{paddingTop: 2, paddingBottom: 2}}
											>
												<Grid item xs={12} sm={12} md={12}>
													<h2>Tipo de visualização</h2>
												</Grid>
												<Grid item xs={1} sm={3} md={4}></Grid>
												<Grid item xs={4} sm={3} md={3}>
													<Button.GenericButtonGreen
														className={isSatelite ? styles.buttonTypeMapActive : styles.buttonTypeMap}
														// href={props.href}
														funcClick={() => console.log("satelite")}
														text="Satelite"
														icon={
															<I.IconSatelliteAlt />
														}
													/>
												</Grid>
												<Grid item xs={4} sm={3} md={3}>
													<Button.GenericButtonGreen
														className={isSatelite ? styles.buttonTypeMapActive : styles.buttonTypeMap}
														// href={props.href}
														funcClick={() => console.log("satelite")}
														text="Navegação"
														icon={
															<I.IconMap />
														}
													/>
												</Grid>
											</Grid> */}
										</Item>
									</Grid>

									<Grid item xs={10} sm={10} md={10}>
										<Item sx={stylesItem}>
											<Grid
												container
												rowSpacing={1}
												columnSpacing={{ xs: 1, sm: 2, md: 3 }}
											>
												<Grid item xs={12} sm={6} md={6}>
													<Item sx={stylesItem}>
														<CircleIcon style={{ color: "#49A09A" }} /> Talhões
													</Item>
												</Grid>
												<Grid item xs={12} sm={6} md={6}>
													<Item sx={stylesItem}>
														<CircleIcon style={{ color: "#EBEF62" }} />
														Parcela
													</Item>
												</Grid>
												<Grid item xs={12} sm={6} md={6}>
													<Item sx={stylesItem}>
														<CircleIcon style={{ color: "#14FFF1" }} />
														Reservatório
													</Item>
												</Grid>
												<Grid item xs={12} sm={6} md={6}>
													<Item sx={stylesItem}>
														<CircleIcon style={{ color: "#AA5D5D" }} />
														Reserva Legal
													</Item>
												</Grid>
												<Grid item xs={12} sm={6} md={6}>
													<Item sx={stylesItem}>
														<CircleIcon style={{ color: "#000" }} />
														Sede
													</Item>
												</Grid>
												<Grid item xs={12} sm={6} md={6}>
													<Item sx={stylesItem}>
														<CircleIcon style={{ color: "#0401AF" }} />
														Corpos hidricos
													</Item>
												</Grid>
											</Grid>
										</Item>
									</Grid>
									{addComplexArea && (
										<Grid item xs={2} sm={2} md={2}>
											<Item sx={styleBtn}>
												<button
													className={styles.propriedadeButtonSalvarBtn}
												>
													Adicionar ponto
												</button>
											</Item>
										</Grid>
									)}
									{addComplexArea && (
										<Grid item xs={2} sm={2} md={2}>
											<Item sx={styleBtn}>
												<button
													className={styles.propriedadeButtonSalvarBtn}
												>
													Finalizar desenho
												</button>
											</Item>
										</Grid>
									)}
								</Grid>
							</Item>
						</Grid>
						<Grid item xs={12} sm={12} md={5} sx={{ overflowX: "none" }}>
							<Item>
								<h2 
									style={{color: "red"}} 
									hidden={property.propriedadeCoordenada && property.propriedadeCoordenada.length > 0}
								>
									É recomendado fazer a delimitação da área no mapa. Escolha a opção "editar área" para fazer essa delimitação.
								</h2>
								<div className={styles.titlePropertyContentLeft}>
									<div>
										<h2>
											{property.nome &&
											property.nome !== ""
												? property.nome
												: "Não informado"}
										</h2>
										<p>
											{property.cpf_cnpj && property.cpf_cnpj !== undefined && property.cpf_cnpj !== ""
												? formatCpfCnpj()
												: "Não informado"}
										</p>
									</div>
									<div className={styles.btn}>
										<Btn onClick={handleClick}>
											<I.IconMoreVert style={styleIcon} />
										</Btn>
										<Menu
											id="basic-menu"
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												"aria-labelledby": "basic-button",
											}}
										>
											<MenuItem onClick={openPropertyToEdit}>
												Editar dados
											</MenuItem>
											<MenuItem onClick={editPropertyArea}>
												Editar área
											</MenuItem>
											<MenuItem onClick={handleOpenDialogDelet}>
												Deletar
											</MenuItem>
											<MenuItem onClick={handleClickAdd}>Adicionar +</MenuItem>
										</Menu>
										<Menu
											id="basic-menu"
											anchorEl={anchorElAdd}
											open={openAdd}
											onClose={handleCloseAdd}
											MenuListProps={{
												"aria-labelledby": "basic-button",
											}}
										>
											<MenuItem onClick={openPlotRegister}>Talhão</MenuItem>
											<MenuItem onClick={addWaterBody}>
												Corpos hidricos
											</MenuItem>
											<MenuItem onClick={addReservoir}>
												Reservatório
											</MenuItem>
											<MenuItem onClick={addHeadQuarters}>Sede</MenuItem>
											<MenuItem onClick={addLegalReserve}>
												Reserva legal
											</MenuItem>
										</Menu>
									</div>
								</div>

								<ul className={styles.propertyContentList}>
									{property.talhoes.map((row) => {
										return (
											<li
												key={row.hash}
												className={styles.propertyContentItemList}
											>
												<DropDownProperty
													item={row.nome}
													area={row.area_hectares}
													idItem={row.id}
													subItem={
														row.subdivisoes.length > 0 ? row.subdivisoes : null
													}
													row={row}
													saveSubdivision={saveSubdivisionData}
													handleDelete={deletePlot}
													handleDeleteItem={deleteSubdivision}
													updatePlot={updatePlotData}
													handleEditArea={editPlotArea}
													handleEditItemArea={editSubdivisionArea}
												/>
											</li>
										);
									})}
									{property.outrasAreasPropriedades.map((row, index) => {
										return (
											<li
												key={index}
												className={
													styles.propertyContentItemList + " " + row.type
												}
											>
												<DropDownProperty
													item={areaType[row.tipoArea]}
													idItem={row.id}
													subItem={null}
													row={row}
													container={styles.propertyVerde}
													handleEditOthersArea={editOthersArea}
													handleDelete={deleteOtherArea}
												/>
											</li>
										);
									})}
								</ul>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Modal
				open={openPropertyRegisterModal}
				onClose={oldProperty ? handleClosePropertyRegisterModal : null}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: 4,
						maxHeight: "80vh",
						overflowY: "auto",
						p: 3,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={3}
						columnSpacing={{ xs: 0, sm: 0, md: 0 }}
						direction={"column"}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p className={styles.registrationTitle}>Cadastrar/editar dados</p>
						</Grid>
						<Grid
							container
							rowSpacing={{ xs: 2, sm: 1, md: 0 }}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							direction={"row"}
						>
							<Grid item xs={12} sm={12} md={6}>
								<div className={styles.registrationInputModal}>
									<input
										type="text"
										placeholder="Nome/Razao *"
										value={property.nome}
										onChange={(e) => setPropertyName(e.target.value)}
									/>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<div className={styles.registrationInputModal}>
									<InputMask
										placeholder="CPF/CNPJ *"
										name="CPF/CNPJ"
										mask={property.cpf_cnpj.length > 11 ? "99.999.999/9999-99" : cpfCnpjMask}
										value={property.cpf_cnpj}
										onChange={(event) => setPropertyCpfCnpj(event.target.value)}
										onBlur={() => setCpfCnpjMask("999.999.999-99")}
										onFocus={() => setCpfCnpjMask("999.999.999-999")}
									/>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<div className={styles.registrationTitle}>
									<p>Preencha os dados de localização</p>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<div className={styles.registrationInputModal}>
									<input
										type="text"
										id="cep"
										placeholder="CEP *"
										value={property.endereco.cep}
										onChange={(e) => setPropertyCep(e.target.value)}
										className="inputClientRegisterArea"
										onKeyUp={(e) => handleChangeCEP(e.target.value)}
									/>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<div className={styles.registrationInputModal}>
									<select
										id="estado"
										onChange={(e) => setPropertyUf(e.target.value)}
										value={property.endereco.uf}
										className={
											property.endereco.uf === "0" && styles.selectInputPlaceholder
										}
									>
										<option value={"0"}>
											{property.endereco.uf !== "0" ? "--------------- *" : "Estado *"}
										</option>
										<option value="AC">Acre</option>
										<option value="AL">Alagoas</option>
										<option value="AP">Amapá</option>
										<option value="AM">Amazonas</option>
										<option value="BA">Bahia</option>
										<option value="CE">Ceará</option>
										<option value="DF">Distrito Federal</option>
										<option value="ES">Espirito Santo</option>
										<option value="GO">Goiás</option>
										<option value="MA">Maranhão</option>
										<option value="MS">Mato Grosso do Sul</option>
										<option value="MT">Mato Grosso</option>
										<option value="MG">Minas Gerais</option>
										<option value="PA">Pará</option>
										<option value="PB">Paraíba</option>
										<option value="PR">Paraná</option>
										<option value="PE">Pernambuco</option>
										<option value="PI">Piauí</option>
										<option value="RJ">Rio de Janeiro</option>
										<option value="RN">Rio Grande do Norte</option>
										<option value="RS">Rio Grande do Sul</option>
										<option value="RO">Rondônia</option>
										<option value="RR">Roraima</option>
										<option value="SC">Santa Catarina</option>
										<option value="SP">São Paulo</option>
										<option value="SE">Sergipe</option>
										<option value="TO">Tocantins</option>
									</select>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<div className={styles.registrationInputModal}>
									<input 
										type="text"
										placeholder="Cidade *" 
										id="cidade"
										value={property.endereco.cidade}
										onChange={(e) => setPropertyCity(e.target.value)} 
									/>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<div className={styles.registrationInputModal}>
									<input 
										type="text" 
										placeholder="Bairro *" 
										id="bairro"
										value={property.endereco.bairro}
										onChange={(e) => setPropertyNeighborhood(e.target.value)} 
									/>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<div className={styles.registrationInputModal}>
									<input 
										type="text"
										placeholder="Endereço *"
										id="endereco"
										value={property.endereco.endereco}
										onChange={(e) => setPropertyExtraAddress(e.target.value)}
									/>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<div className={styles.registrationInputModal}>
									<input
										type="number"
										placeholder="Área em hectares *"
										id="hectare"
										value={property.area_hectares}
										onChange={(e) => setPropertyArea(Number(e.target.value))}
									/>
								</div>
							</Grid>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={{ xs: 2, sm: 1, md: 0 }}
								columnSpacing={{ xs: 1, sm: 2, md: 3 }}
								display="flex"
								alignItems="center"
								justifyContent="end"
								flexDirection={{
									xs: "column-reverse",
									sm: "column-reverse",
									md: "row",
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={oldProperty ? handleClosePropertyRegisterModal : null}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={handleClickAlert}
									>
										Salvar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<Modal
				open={openPlot}
				onClose={handleCloseData}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: 4,
						p: 3,
						m: 0,
					}}
				>
					<Grid
						container
						rowSpacing={3}
						columnSpacing={{ xs: 0, sm: 0, md: 0 }}
						direction={"column"}
					>
						<Grid item xs={12} sm={12} md={12}>
							<p className={styles.registrationTitle}>
								Cadastrar/Alterar dados
							</p>
						</Grid>
						<Grid
							container
							rowSpacing={{ xs: 2, sm: 1, md: 0 }}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							direction={"row"}
						>
							<Grid item xs={12} sm={12} md={6}>
								<div className={styles.registrationInputModal}>
									<input
										type="text"
										placeholder="Identificação *"
										value={plot.nome}
										onChange={(e) => setPlotName(e.target.value)}
									/>
								</div>
							</Grid>

							<Grid item xs={12} sm={12} md={6}>
								<div className={styles.registrationInputModal}>
									<input
										type="text"
										placeholder="Área em hectares *"
										value={plot.area_hectares}
										onChange={(e) => setPlotArea(e.target.value)}
									/>
								</div>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Grid
								container
								rowSpacing={{ xs: 2, sm: 1, md: 0 }}
								columnSpacing={{ xs: 1, sm: 2, md: 3 }}
								display="flex"
								alignItems="center"
								justifyContent="end"
								flexDirection={{
									xs: "column-reverse",
									sm: "column-reverse",
									md: "row",
								}}
							>
								<Grid item xs={12} sm={12} md={3}>
									<button
										type="button"
										onClick={handleCloseData}
										className={styles.registrationButtonCancelar}
									>
										Cancelar
									</button>
								</Grid>
								<Grid item xs={12} sm={12} md={3}>
									<button
										className={styles.registrationButtoncadastrar}
										type="button"
										onClick={savePlotData}
									>
										Salvar
									</button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Snackbar
					open={openAlert}
					autoHideDuration={4500}
					onClose={handleCloseAlert}
				>
					<Alert
						onClose={handleCloseAlert}
						severity={typeMessage}
						sx={{ width: "100%" }}
					>
						{message}
					</Alert>
				</Snackbar>
			</Stack>
			<Dialog
				open={openDialog}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Todos os dados relacionados com {removiItem.nome} serão excluidos.
						Deseja excluir {removiItem.nome}?
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
				>
					<div className={styles.dialogActionsButtons}>
						<button
							className={styles.ButtonCancelarModal}
							onClick={handleCloseDialog}
						>
							Cancelar
						</button>
						<button
							className={styles.ButtonColherModal}
						>
							Deletar
						</button>
					</div>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openDialogPropriedade}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleCloseDialogDelet}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						Todos os dados relacionados com serão excluidos. Deseja excluir ?
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
				>
					<div className={styles.dialogActionsButtons}>
						<button
							className={styles.ButtonCancelarModal}
							onClick={handleCloseDialogDelet}
						>
							Cancelar
						</button>
						<button
							className={styles.ButtonColherModal}
							onClick={deleteProperty}
						>
							Deletar
						</button>
					</div>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openDrawModal}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setOpenDrawModal(true)}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						É necessário desenhar a área no mapa.
					</DialogContentText>
				</DialogContent>
				<DialogActions
					sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
				>
					<div className={styles.dialogActionsButtons}>
						<button
							className={styles.ButtonColherModal}
							onClick={() => setOpenDrawModal(false)}
						>
							Confirmar
						</button>
					</div>
				</DialogActions>
			</Dialog>
		</div>
	);
}
export default Property;
