import { ManagementRepository } from "../repository/management_repository";

export class ManagementService {

	constructor() {
		this.managementRepository = new ManagementRepository();
	}

	async save(management) {
		const savedManagement = await this.managementRepository.save(management);
		return savedManagement;
	}

	async update(management) {
		const updatedManagement = await this.managementRepository.update(management);
		return updatedManagement;
	}

	async changeVisibility(id) {
		await this.managementRepository.changeVisibility(id);
	}

	async getByProperty(propertyId, params) {
		const managementData = await this.managementRepository.getByProperty(propertyId, params);
		return managementData.managements;
	}

	async getById(managementId) {
		const management = await this.managementRepository.getById(managementId);
		return management;
	}

	async delete(managementId) {
		const management = await this.managementRepository.delete(managementId);
		return management;
	}
}