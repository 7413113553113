import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect, useState } from "react";
import InputSearch from "../../../components/SearchHeader/inputSeach";
import TopMenu from "../../../components/TopMenu";
//import components
import Search from "../../../components/SearchHeader";
import Table from "../../../components/TableGenerator";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Link, useParams } from "react-router-dom";
import styles from "./CultureEmbalagens.module.css";
import { FarmService } from "../../../service/farm_service";
import Loading from "../../../components/Loading";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

//column
const columns = [
	{
		id: "name",
		label: "Nome",
	},
	{
		id: "quantity",
		label: "Quantidade",
	},
	{
		id: "measureUnit",
		label: "Unidade de medida",
	},
]; 

function CultureEmbalagens() {
	const [search, setSearch] = useState("");	
	const [farmPackages, setFarmPackages] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { id } = useParams();

	const farmService = new FarmService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const farmPackageParams = {
			page: 1,
			limit: 100000,
			sortBy: JSON.stringify([
				{
					nome: "asc"
				},
			])
		};

		const packagesByFarm = await farmService.getPackages(farmPackageParams, id);
		const farmpackagesToShow = packagesByFarm.map((farmPackage) => {
			return {
				id: farmPackage.id,
				name: farmPackage.nome,
				quantity: farmPackage.quantidade,
				measureUnit: farmPackage.unidadeMedida.sigla,
			};
		});
		setFarmPackages(farmpackagesToShow);
		setIsLoading(false);
	};

	const dataFilter = farmPackages.filter((item) => {
		return JSON.stringify(item).toLowerCase().includes(search.toLowerCase());
	});

	function handleClick(e) {
		setSearch(e.target.value);
	}

	const handleDelete = async (id) => {
		await farmService.changeVisibilityPackage(id);
		initData();
	};

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu open={["register", "culture"]} help="culture" />
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
										display: "flex"
									}}
								>
									<Search
										// title="Embalagens"
										headerComponents={
											<Link to={"/culturas"} className={styles.headerRegisterAreaButton}>
												<ArrowBackIcon sx={{ mr: 2 }} />
												Embalagens
											</Link>}
										href={`cadastroEmbalagem/?idReturn=${id}`}
										textButton="Nova Embalagem"
									>
										<InputSearch func={handleClick} />
									</Search>
								</Item>
							</Grid>
							<Grid item xs={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Table
										data={dataFilter}
										columns={columns}
										handleDelete={handleDelete}
										editHref={`cadastroEmbalagem/?idReturn=${id}&id=`}
									/>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}

export default CultureEmbalagens;
